import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IoIosHome, IoIosCheckmark, IoIosList, IoIosWarning, IoIosText, IoIosLock } from 'react-icons/io';
import { getUser } from '../../../data/selectors/userAuthSelectors';
import { NavLink } from 'react-router-dom';
import styles from './PageNavButton.css';

export default function PageNavButton({ text, link }) {
  const user = useSelector(getUser);
  const iconKey = {
    'KNOW Home': <IoIosHome />,
    'Methodology': <IoIosCheckmark />,
    'Studies': <IoIosList />,
    'Interactions': <IoIosWarning />,
    'Clinical Pearls': <IoIosText />,
  };
  
  if(!user.email){
    iconKey.Studies = <IoIosLock />;
  }

  const correctPageNavUserExperience = () => {
    return (<div>
      <NavLink activeClassName={styles.navLink} to={link} exact={true}>
        {iconKey[text]}
        <span>{text}</span>
      </NavLink>
    </div>);
  };

  return (
    <>
      { correctPageNavUserExperience() }
    </>
  );
}

PageNavButton.propTypes = {
  text: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};
