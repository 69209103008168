import React, { useState, useEffect } from 'react';
import { resetPassword } from '../../services/auth';
import styles from './PasswordReset.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner.jsx';

export default function PasswordReset() {
  const [passwordOne, setPasswordOne] = useState('');
  const [passwordTwo, setPasswordTwo] = useState('');
  const [buttonDisable, setButtonDisable] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePasswordOne = ({ target }) => {
    setPasswordOne(target.value);
  };

  const handlePasswordTwo = ({ target }) => {
    setPasswordTwo(target.value);
  };

  useEffect(() => {
    if(passwordOne !== '' && passwordOne === passwordTwo){
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [passwordOne, passwordTwo]);

  const handleChangePassword = () => {
    setLoading(true);
    const token = new URL(document.location).searchParams.get('token');
    resetPassword(token, passwordOne)
      .then(() => {
        setErrorMessage('Success!');
        setLoading(false);
      })
      .catch(err => {
        if(err.status === 401 || err.message === 'jwt malformed'){
          setErrorMessage('Please go back to your email to follow the password reset link once more.');
        } else {
          setErrorMessage(err.message);
        }
        setLoading(false);
      });
  };

  if(loading) return <LoadingSpinner />;

  return (
    <div className={styles.background}>
      <div className={styles.forgot}>
        <h1>Reset Your Password</h1>
        {errorMessage && <p className={styles.message}>{errorMessage}</p>}
        <section>
          <p>
            Your new password must:
            <p>
              <ul>
                <li>Be different than your old password,</li>
                <li>Be at least 8 characters long,</li>
                <li>Contain at least:
                  <ul>
                    <li>one upper case letter,</li>
                    <li>one lower case letter,</li>
                    <li>one number,</li>
                    <li>one of the following symbols: !@#$%^&*</li>
                  </ul>
                </li>
              </ul>
            </p>
          </p>
        </section>
        <div>
          <p>Please enter your new password:</p>
          <input type='password' value={passwordOne} onChange={handlePasswordOne} />
        </div>
        <div>
          <p>Please confirm your new password:</p>
          <input type='password' value={passwordTwo} onChange={handlePasswordTwo} />
        </div>
        <button disabled={buttonDisable} onClick={handleChangePassword}>Change your password</button>
      </div>
    </div>
  );
}
