import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './resultUtilityButtons.css';
import PropTypes from 'prop-types';
import { summaryTagHeadersDict } from '../../../../utils/tagHeadersDicts';
import { sysReviewFields, clinicalTrialFields, observationalFields, caseReportFields, unknownFields, ignoreEntries } from '../../../../utils/displayKeys';

ResultUtilityButtons.propTypes = {
  study: PropTypes.object.isRequired
};

export default function ResultUtilityButtons({ study }) {
  const [statusMessage, setStatusMessage] = useState('');
  const [statusMessageColor, setStatusMessageColor] = useState('');

  const handleCopy = (text, result) => {
    if(result){
      setStatusMessageColor(styles.greenMessage);
      setStatusMessage('Copied to clipboard');
    } else {
      setStatusMessageColor(styles.redMessage);
      setStatusMessage('There was a problem copying to clipboard');
    }
    setTimeout(() => {
      setStatusMessage('');
    }, 3000);
  };

  const handleCopyRef = (text, result) => {
    if(text && result){
      setStatusMessageColor(styles.greenMessage);
      setStatusMessage('Copied to clipboard');
    } else if(text === null) {
      setStatusMessageColor(styles.redMessage);
      setStatusMessage('This article does not have a reference.');
    }
    else {
      setStatusMessageColor(styles.redMessage);
      setStatusMessage('There was a problem copying to clipboard');
    }
    setTimeout(() => {
      setStatusMessage('');
    }, 3000);
  };

  const removeHighlighter = string => {
    if(!string || typeof string !== 'string') return string;
    return string.replace(/<\/?highlighter>/g, '');
  };

  const studyKeyToText = keysArray => {
    let studyText = '';
    const additionalCopyKeys = ['summaryTitle', 'year', 'author'];
    [...additionalCopyKeys, ...keysArray].forEach(key => {
      if(!study[key]) return;
      const value = study[key];
      if(ignoreEntries.includes(value)) return;
      studyText += `\n${summaryTagHeadersDict[key] || key}:\n${removeHighlighter(value)}\n`;
    });
    return studyText;
  };

  const studyAsText = () => {
    if(study.docType === 'SupportLit') return;
    switch(study.studyTypeInt) {
      case 1: // Systematic Review / Meta Analysis
        return studyKeyToText(sysReviewFields);
      case 2: // Clinical Trials
        return studyKeyToText(clinicalTrialFields);
      case 3: // Observational
        return studyKeyToText(observationalFields);
      case 4: // Case Reports
        return studyKeyToText(caseReportFields);
      case 5: // Unknown
        return studyKeyToText(unknownFields);
      default:
        break;
    }
  };

  const generateLink = () => {
    const url = new URL(`${process.env.FE_URL}/shared`);
    url.searchParams.set('id', study._id);
    url.searchParams.set('docType', study.docType);
    return url;
  };

  return (
    <div className={styles.utilityButtonsContainer}>
      {statusMessage ? <span className={statusMessageColor}>{statusMessage}</span> : ''}
      {study.docType !== 'SupportLit' && <CopyToClipboard text={study.reference} onCopy={handleCopyRef}>
        <button type="button">Copy This Reference</button>
      </CopyToClipboard>}
      <CopyToClipboard text={generateLink()} onCopy={handleCopy}>
        <button type="button">Copy Link to This Result</button>
      </CopyToClipboard>
      {study.docType !== 'SupportLit' && <CopyToClipboard text={studyAsText()} onCopy={handleCopy}>
        <button type="button">Copy This Item</button>
      </CopyToClipboard>}
    </div>
  );
}
