import React, { useState, useEffect } from 'react';
import styles from './feedback.css';
import { submitFeedback } from '../../services/feedback';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

export default function Feedback() {
  const [feedback, setFeedback] = useState('');
  const [message, setMessage] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [successfulSubmissionFlag, setSuccessfulSubmissionFlag] = useState(false);
  const { email } = useSelector(getUser);
  const userLoading = useSelector(getUserLoading);
  const { feedbackContainer, imAButton } = styles;

  useEffect(() => {
    if(feedback) setSubmitDisabled(false);
    else setSubmitDisabled(true);
  }, [feedback]);

  if(userLoading || submitLoading) {
    return <LoadingSpinner />;
  }

  if(!email) {
    return (
      <div className={feedbackContainer}>
        <h1>You must be logged in to submit feedback</h1>
      </div>
    );
  }
  
  const handleFeedbackChange = event => setFeedback(event.target.value);

  const handleSubmit = event => {
    event.preventDefault();

    setSubmitLoading(true);
    submitFeedback(email, feedback)
      .then(() => {
        setMessage('Thank you for your feedback!');
        setSubmitLoading(false);
        setSuccessfulSubmissionFlag(true);
      })
      .catch(err => {
        setMessage(err.message);
      });
  };

  return (
    <div className={feedbackContainer}>
      <h1>Feedback</h1>
      <p>Please let us know if you have any comments!</p>
      <form onSubmit={handleSubmit}>
        <textarea placeholder='comments' value={feedback} onChange={handleFeedbackChange} />
        <input type='submit' disabled={submitDisabled || successfulSubmissionFlag} className={imAButton} value='Send feedback' />
      </form>
      <p>
        {message}
      </p>
    </div>
  );
}
