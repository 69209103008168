import React from 'react';
import styles from './interactions.css';
const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef

const Interactions = () => (
  <div className={styles.interactions}>
    <p className='h5'>The Interactions Section is a feature that is being developed over the next year.<br />In the meantime, here are two helpful documents:</p>
    <section id='seely'>
      <p className='h5'>A Strategy for Controlling Potential Interactions Between Natural Health Products and Chemotherapy</p>
      <a href={`${ASSETS_PATH}/interactions_seely.pdf`} target='_blank' rel="noopener noreferrer">
        <img src={`${ASSETS_PATH}/interactions_seely_img.png`} alt='seely document image'/>
      </a>
    </section>
    <section id='ctca'>
      <p className='h5'>Dietary Supplements used concurrently with Conventional Treatment – A Methodology for Prevention of Interactions</p>
      <a href={`${ASSETS_PATH}/ctca_interactions_poster.pdf`} target='_blank' rel="noopener noreferrer">
        <img src={`${ASSETS_PATH}/ctca_img.png`} alt='ctca document image' />
      </a>
    </section>
  </div>
);

export default Interactions;
