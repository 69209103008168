// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".jdPOfLr0DR9iP-DjYXBf1 {\r\n  background-color: #683266;\r\n} \r\n\r\n  .jdPOfLr0DR9iP-DjYXBf1 span {\r\n    color: white;\r\n  }\r\n\r\n.jdPOfLr0DR9iP-DjYXBf1:hover {\r\n  background-color: #683266 !important;\r\n}", "",{"version":3,"sources":["PageNavButton.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;AAK3B;;EAHE;IACE,YAAY;EACd;;AAGF;EACE,oCAAoC;AACtC","file":"PageNavButton.css","sourcesContent":[".navLink {\r\n  background-color: #683266; \r\n\r\n  span {\r\n    color: white;\r\n  }\r\n}\r\n\r\n.navLink:hover {\r\n  background-color: #683266 !important;\r\n}"]}]);
// Exports
exports.locals = {
	"navLink": "jdPOfLr0DR9iP-DjYXBf1"
};
module.exports = exports;
