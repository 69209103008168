// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._25506Q7oomZckCFxiscStF {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n  margin: 3rem;\r\n}\r\n\r\n  ._25506Q7oomZckCFxiscStF h1 {\r\n    margin-bottom: 1rem;\r\n  }\r\n\r\n  ._25506Q7oomZckCFxiscStF img {\r\n    margin: 1rem;\r\n    margin-bottom: 3rem;\r\n  }\r\n", "",{"version":3,"sources":["donate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,YAAY;AAUd;;EARE;IACE,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,mBAAmB;EACrB","file":"donate.css","sourcesContent":[".donate {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  text-align: center;\r\n  align-items: center;\r\n  margin: 3rem;\r\n\r\n  h1 {\r\n    margin-bottom: 1rem;\r\n  }\r\n\r\n  img {\r\n    margin: 1rem;\r\n    margin-bottom: 3rem;\r\n  }\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"donate": "_25506Q7oomZckCFxiscStF"
};
module.exports = exports;
