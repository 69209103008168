import React from 'react';
const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef

const ExternalLinks = () => (
  <>
    <a href='https://oncanp.org/' target='_blank' rel="noopener noreferrer">
      <img src={`${ASSETS_PATH}/oncanp_logo.png`} alt='oncanp logo'></img>
    </a>

    <a href='http://oicc.ca/en/' target='_blank' rel="noopener noreferrer">
      <img src={`${ASSETS_PATH}/oicc_logo.png`} alt='oncanp logo'></img>
    </a>

    <a href='https://www.naturalmedicinejournal.com/' target='_blank' rel="noopener noreferrer">
      <img src={`${ASSETS_PATH}/nmj_logo.jpg`} alt='oncanp logo'></img>
    </a>
  </>
);

export default ExternalLinks;
