import React, { useState, useEffect } from 'react';
import { userSignup } from '../../services/signup';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import styles from './Signup.css';

export default function Signup() {
  const [firstNameField, setFirstNameField] = useState('');
  const [lastNameField, setLastNameField] = useState('');
  const [displayNameField, setDisplayNameField] = useState('');
  const [passwordField, setPasswordField] = useState('');
  const [repeatPwField, setRepeatPwField] = useState('');
  // const [emailField] = useState('Automatically set Email');
  // const [orgField] = useState('Automatically set Organization');
  // const [accessExpField] = useState('Automatically set access expiration');
  const [submitDisable, setSubmitDisable] = useState(true);
  const [message, setMessage] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if(passwordField !== '' && passwordField === repeatPwField){
      setSubmitDisable(false);
    } else {
      setSubmitDisable(true);
    }
  }, [passwordField, repeatPwField]);

  if(submitLoading) return <LoadingSpinner />;

  const messageWithClear = (text, timeout = 3000) => {
    setMessage(text);
    setTimeout(() => setMessage(''), timeout);
  };
  
  const handleFirstNameChange = ({ target }) => setFirstNameField(target.value);
  const handleLastNameChange = ({ target }) => setLastNameField(target.value);
  const handleDisplayNameFieldChange = ({ target }) => setDisplayNameField(target.value);
  const handlePasswordFieldChange = ({ target }) => setPasswordField(target.value);
  const handleRepeatPwFieldChange = ({ target }) => setRepeatPwField(target.value);
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitLoading(true);
    const token = new URL(document.location).searchParams.get('token');
    const userInfo = {
      first_name: firstNameField,
      last_name: lastNameField,
      display_name: displayNameField,
      password: passwordField,
      token
    };
    userSignup(userInfo)
      .then(newUser => {
        messageWithClear(newUser.message, 10000);
        setSubmitLoading(false);
      })
      .catch(err => {
        messageWithClear(err.message, 10000);
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <div className={styles.signupContainer}>
        <h1>Signup</h1>
        <p className={styles.message}>{message}</p>
        <div className={styles.formContainer}>
          <form>
            <label htmlFor='firstName'>First Name</label>
            <input id='firstName' type='text' value={firstNameField} onChange={handleFirstNameChange} placeholder='First name' />
            <label htmlFor='lastName'>Last Name</label>
            <input id='lastName' type='text' value={lastNameField} onChange={handleLastNameChange} placeholder='Last name' />
            <label htmlFor='displayName'>Display Name</label>
            <input id='displayName' type='text' value={displayNameField} onChange={handleDisplayNameFieldChange} placeholder='Display name' />
            <label htmlFor='password1'>Password</label>
            <input id='password1' type='password' value={passwordField} onChange={handlePasswordFieldChange} placeholder='Password' />
            <label htmlFor='password2'>Repeat Password</label>
            <input id='password2' type='password' value={repeatPwField} onChange={handleRepeatPwFieldChange} placeholder='Repeat password' />
            {/* <input type='text' value={emailField} readOnly={true} />
            <input type='text' value={orgField} readOnly={true} />
            <input type='text' value={accessExpField} readOnly={true} /> */}
            <button disabled={submitDisable} type='submit' onClick={handleSubmit}>Sign Up</button>
          </form>
        </div>
        <p>{message}</p>
      </div>
    </>
  );
}
