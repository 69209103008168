import React, { useState, useEffect } from 'react';
import AllCancerTypes from '../components/methodology/methods/AllCancerTypes';
import Breast from '../components/methodology/methods/Breast';
import Neuropathy from '../components/methodology/methods/Neuropathy';
import Thoracic from '../components/methodology/methods/Thoracic';
import Curcumin from '../components/methodology/methods/Curcumin';

export const useMethods = () => {
  const [method, setMethod] = useState('all');
  const [component, setComponent] = useState();

  const displayDictionary = {
    all: <AllCancerTypes />,
    breast: <Breast />,
    neuropathy: <Neuropathy />,
    thoracic: <Thoracic />,
    curcumin: <Curcumin />
  };

  useEffect(() => {    
    setComponent(displayDictionary[method]);
  }, [method]);

  const handleClick = ({ target }) => {
    setMethod(target.name);
  };

  return { handleClick, component, method };
};
