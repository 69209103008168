import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getUser } from '../../data/selectors/userAuthSelectors';
import styles from './EditUser.css';
import { patchUser } from '../../services/orgAdmin';

const propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.number.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    last_seen: PropTypes.string.isRequired,
    display_name: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired,
    access_expiration: PropTypes.string.isRequired
  }),
  closeCallback: PropTypes.func.isRequired
};

export default function EditUser({ user, closeCallback }) {
  if(!user) return null;
  const defaultDate = new Date(user.access_expiration);
  const defaultFormattedDate = defaultDate.toISOString().substr(0, 10);

  const [role, setRole] = useState(user.role);
  const [accessExpiration, setAccessExpiration] = useState(defaultFormattedDate);
  const admin = useSelector(getUser);

  const handleUserRole = ({ target }) => setRole(target.value);
  const handleAccessExpiration = ({ target }) => setAccessExpiration(target.value);
  const handleCancel = () => closeCallback(null);
  const handleSubmit = () => {
    patchUser({ role, access_expiration: accessExpiration }, user._id)
      .then(editedUser => closeCallback(editedUser))
      .catch(err => console.log(err));
  };
  
  return (
    <div className={styles.backgroundDiv}>
      <div className={styles.editModal}>
        <p>Edit User</p>
        <table>
          <tbody>
            <tr>
              <td>First Name</td>
              <td>{user.first_name}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{user.last_name}</td>
            </tr>
            <tr>
              <td>Display Name</td>
              <td>{user.display_name}</td>
            </tr>
            <tr>
              <td>Email</td>
              <td>{user.email}</td>
            </tr>
            <tr>
              <td>Role</td>
              <td>
                <select value={role} onChange={handleUserRole}>
                  <option value='regular'>
                    Regular
                  </option>
                  <option value='org-admin'>
                    Org Admin
                  </option>
                  {admin.organizations === 1 && 
                  <option value='allied'>Allied</option>}
                  {admin.organizations === 1 &&
                  <option value='student'>Student</option>}
                  {admin.organizations === 1 && admin.role === 'know-admin' &&
                  <option value='know-admin'>Know Admin</option>}
                </select>
              </td>
            </tr>
            <tr>
              <td>Access Expiration</td>
              <td>
                <input type='date' value={accessExpiration} onChange={handleAccessExpiration} />
              </td>
            </tr>
          </tbody>
        </table>
        <button type='button' onClick={handleCancel}>Cancel Edits</button>
        <button type='button' onClick={handleSubmit}>Save Edits</button>
      </div>
    </div>
  );
}

EditUser.propTypes = propTypes;
