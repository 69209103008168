import { request } from './request';

export const login = (email, password) => {
  return request('/auth/login', 'POST', { email, password });
};

export const verify = () => {
  return request('/auth/verify', 'GET');
};

export const logout = () => {
  return request('/auth/logout', 'GET');
};

export const requestPasswordResetLink = email => {
  return request('/auth/forgot-password', 'POST', { email });
};

export const resetPassword = (token, password) => {
  return request('/auth/reset-password', 'PATCH', { token, password });
};
