// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1OCfd4US-4Nwcmokk_zprt {\r\n  width: 100%;\r\n  bottom: 0;\r\n  position: relative;\r\n\r\n  font-family: 'Roboto', sans-serif;\r\n  color: #333333;\r\n  font-size: 1rem;\r\n  padding-top: 1em;\r\n  padding-bottom: 1em;\r\n  font-size: small;\r\n  font-weight: bold;\r\n  text-align: center;\r\n  background-color: #dddddd;\r\n  line-height: 1.3;\r\n}\r\n", "",{"version":3,"sources":["Footer.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,SAAS;EACT,kBAAkB;;EAElB,iCAAiC;EACjC,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB","file":"Footer.css","sourcesContent":[".Footer {\r\n  width: 100%;\r\n  bottom: 0;\r\n  position: relative;\r\n\r\n  font-family: 'Roboto', sans-serif;\r\n  color: #333333;\r\n  font-size: 1rem;\r\n  padding-top: 1em;\r\n  padding-bottom: 1em;\r\n  font-size: small;\r\n  font-weight: bold;\r\n  text-align: center;\r\n  background-color: #dddddd;\r\n  line-height: 1.3;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"Footer": "_1OCfd4US-4Nwcmokk_zprt"
};
module.exports = exports;
