import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import { verifyUser } from '../../data/actions/userAuthActions';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { Redirect } from 'react-router-dom';
import { getSingleSummarizedStudy } from '../../services/studies';
import SummarizedResultItem from '../studies/results/SummarizedResultItem';
import { yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects } from '../../utils/dropdownSeedData';

export default function SingleResult() {
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const [resultLoading, setResultLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [displayComponent, setDisplayComponent] = useState('');  

  const searchParams = new URL(window.location).searchParams;
  const id = searchParams.get('id');
  const docType = searchParams.get('docType');

  const filterGroupArrays = [yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects];
  const filterOptions = () => {
    return filterGroupArrays.reduce((initialState, filterArray) => {
      const [filterName, allOption] = filterArray;
      return { ...initialState, [filterName]: [allOption] };
    }, {});
  };
  const filteredKeys = Object.entries(filterOptions()).filter(([, [firstFilter]]) => !firstFilter.startsWith('All ')).map(([key]) => key);

  useEffect(() => {
    dispatch(verifyUser());
    try {
      switch(docType) {
        case 'Microtag':
        case 'Summary': getSingleSummarizedStudy(id)
          .then(res => setDisplayComponent(<SummarizedResultItem study={res.results} isOpen={true} filteredKeys={filteredKeys} />));
          break;
        default: break;
      }
      setResultLoading(false);
    }
    catch(err) {
      setMessage(err);
      setResultLoading(false);
    }
  }, []);

  if(userLoading || resultLoading) return <LoadingSpinner />;

  if(!user.email) return <Redirect to='/login' />;

  return (
    <>
      {message}
      {displayComponent}
    </>
  );
}
