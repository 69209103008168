import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { fetchPearls } from '../../services/pearls';
import { getUserLoading, getUser } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import Pearl from './Pearl';
import { pearlsContainer, resultsSection } from './pearls.css';

export default function ClinicalPearls() {
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const [pearls, setPearls] = useState([]);
  const [message, setMessage] = useState('');
  const [pearlsLoading, setPearlsLoading] = useState(false);

  useEffect(() => {
    if(user.email) {
      setPearlsLoading(true);
      fetchPearls()
        .then(res => {
          setPearls(res);
          setPearlsLoading(false);
        })
        .catch(err => setMessage(err.message));
    }
  }, [user]);

  if(userLoading || pearlsLoading) return <LoadingSpinner />;
  if(!user.email) return <Redirect to='/login' />;

  const clinicalPearls = pearls.map(pearl => (
    <li key={pearl._id}>
      <Pearl pearl={pearl} />
    </li>
  ));

  return (
    <section className={pearlsContainer}>
      <h1>Clinical Pearls</h1>
      <p>{message}</p>
      <ul className={resultsSection}>
        {clinicalPearls}
      </ul>
    </section>
  );
}
