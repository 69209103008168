import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getOrgUsers, removeFromOrg, deleteUsers } from '../../services/orgAdmin';
import EditUser from '../editUser/EditUser';
import formatDate from '../../utils/formatDate';
import styles from './OrgUserTable.css';
import OrgUsersAccessExpiration from './orgUsersAccessExpiration/OrgUsersAccessExpiration';
import { exportUsers } from './exportUsers';

const propTypes = {
  orgId: PropTypes.number,
  countCallback: PropTypes.func,
  messageCallback: PropTypes.func.isRequired,
  loadingCallback: PropTypes.func.isRequired,
  admin: PropTypes.shape({
    organizations: PropTypes.number.isRequired,
    _id: PropTypes.number.isRequired,
    role: PropTypes.string.isRequired,
    access_expiration: PropTypes.string.isRequired
  }).isRequired,
  open: PropTypes.bool,
  orgName: PropTypes.string
};

export default function OrgUserTable({ orgId, countCallback, admin, messageCallback, loadingCallback, open = false, orgName }) {
  if(!orgId) return null;

  const [orgUsers, setOrgUsers] = useState([]);
  const [userEdit, setUserEdit] = useState(null);
  const [userPage, setUserPage] = useState(1);
  const [userSearchText, setUserSearchText] = useState('');
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [filteredUsersFlag, setFilteredUsersFlag] = useState(false);
  const [usersLoading, setUsersLoading] = useState(true);
  const [modalState, setModalState] = useState(false);
  const [allAccessExpirations, setAllAccessExpirations] = useState(false);

  useEffect(() => {
    setUsersLoading(true);
    getOrgUsers(orgId)
      .then(res => {
        setUserPage(1);
        setUserSearchText('');
        setFilteredUsersFlag(false);
        setOrgUsers(res);
        setDisplayedUsers(res.slice(0, 19));
        setUsersLoading(false);
      })
      .catch(err => {
        console.log(err.message);
        setUsersLoading(false);
      });
  }, [orgId]);

  useEffect(() => {
    if(countCallback) countCallback(orgUsers.length);
    if(filteredUsersFlag) {
      const matchingUsers = orgUsers.filter(user => user.email.includes(userSearchText));
      setDisplayedUsers(matchingUsers);
    } else {
      setDisplayedUsers(orgUsers.slice((userPage * 20 - 20), (userPage * 20 - 1)));
    }
  }, [orgUsers]);

  //This is bugged: causes the loading spinner blink
  useEffect(usersLoading => {
    loadingCallback(usersLoading);
  }, [usersLoading]);

  const handleUserSearchText = ({ target }) => setUserSearchText(target.value);
  const clearSoughtUsers = () => {
    setDisplayedUsers(orgUsers.slice(0, 19));
    setFilteredUsersFlag(false);
    setUserSearchText('');
  };
  const calcShowingOf = () => {
    const calc = userPage * 20;
    if(calc > orgUsers.length) return orgUsers.length;
    return calc;
  };
  const downDisabled = userPage === 1 || filteredUsersFlag;
  const upDisabled = orgUsers.length < (userPage * 20) || filteredUsersFlag;
  const pageUp = () => {
    const newPage = userPage + 1;
    setUserPage(newPage);
    setDisplayedUsers(orgUsers.slice((newPage * 20 - 20), (newPage * 20 - 1)));
  };
  const pageDown = () => {
    const newPage = userPage - 1;
    setUserPage(newPage);
    setDisplayedUsers(orgUsers.slice((newPage * 20 - 20), (newPage * 20 - 1)));
  };
  const showingOf = `Showing users ${userPage * 20 - 19}-${calcShowingOf()} of ${orgUsers.length}`;

  const searchUsers = event => {
    event.preventDefault();
    const matchingUsers = orgUsers.filter(user => user.email.includes(userSearchText));
    setDisplayedUsers(matchingUsers);
    if(userSearchText === '') setFilteredUsersFlag(false);
    else setFilteredUsersFlag(true);
    setUserPage(1);
  };

  const userInfo = displayedUsers.map((user, i) => (
    <tr key={user._id}>
      <td>{user.first_name}</td>
      <td>{user.last_name}</td>
      <td>{user.email}</td>
      <td>{user.display_name}</td>
      <td>{user.role}</td>
      <td>{formatDate(user.access_expiration)}</td>
      <td>{formatDate(user.last_seen)}</td>
      <td>
        <button type='button' onClick={() => {
          if(admin.role === 'org-admin' && Number(user._id) === Number(admin._id)) {
            messageCallback('An admin cannot remove themself from an organization.');
            return;
          }
          const confirmation = window.confirm('Are you sure you want to remove this user? This action will permanently delete the user and will be irreversible.');
          if(!confirmation) {
            messageCallback('Delete cancelled.');
            return;
          } else {
            removeFromOrg(user._id, user.organizations)
              .then(() => {
                const newUserArray = [...orgUsers];
                newUserArray.splice(i, 1);
                const newDisplayedUsers = displayedUsers.filter(displayedUser => displayedUser._id !== user._id);
                setDisplayedUsers(newDisplayedUsers);
                messageCallback('User removed.');
                setOrgUsers(newUserArray);
              });
          }
        }}>Remove From Org.</button>
      </td>
      <td>
        <button type='button' onClick={() => {
          if(admin.role === 'org-admin' && Number(user._id) === Number(admin._id)) {
            messageCallback('An admin cannot edit themself.');
            return;
          }
          setUserEdit(user);
          setModalState(true);
        }}>Edit User</button>
      </td>
    </tr>
  ));

  const closeModalCallback = (editedUser) => {
    if(editedUser){
      const userIndex = orgUsers.map(user => user.email).indexOf(editedUser.email);
      const newUserArray = [...orgUsers];
      newUserArray.splice(userIndex, 1, editedUser);
      setOrgUsers(newUserArray);
      messageCallback(`${editedUser.email} account details edited.`);
    }
    setUserEdit(null);
    setModalState(false);
  };

  const handleDeleteAllOrgUsers = () => {
    const confirmation = window.confirm('Are you sure you want to remove ALL regular users? This action will permenantly delete the users and will be irreversible.');
    if(!confirmation) {
      messageCallback('Delete cancelled.');
      return;
    }
    const targetUsers = orgUsers.filter(user => user.role === 'regular')
      .map(user => ({ _id: user._id, organizations: user.organizations }));
    const doubleConfirmation = window.confirm(`You are ABSOLUTELY sure you want to delete all regular users? You are about to delete ${targetUsers.length} users.`);
    if(!doubleConfirmation) {
      messageCallback('Delete cancelled.');
      return;
    }
    deleteUsers(targetUsers)
      .then(() => {
        setUsersLoading(true);
        getOrgUsers(admin.organizations)
          .then(res => setOrgUsers(res));
        setUsersLoading(false);
      });
  };
  const handleAllUserExpirations = () => {
    setModalState(true);
    setAllAccessExpirations(true);
  };
  const accessModalCallback = updatedUsers => {
    if(updatedUsers) {
      const newUserObj = orgUsers.reduce((acc, curr) => {
        acc[curr._id] = curr;
        return acc;
      }, {});
      updatedUsers.forEach(user => newUserObj[user._id] = user);
      const newUserArray = Object.values(newUserObj);
      setOrgUsers(newUserArray);
      messageCallback(`All users's access set to expire on ${formatDate(updatedUsers[0].access_expiration)}`);
    }
    setAllAccessExpirations(false);
    setModalState(false);
  };

  const handleUserExport = () => {
    exportUsers(orgUsers, orgName);
  };

  const modalBlur = modalState ? { filter: 'blur(2px)' } : {};

  return (
    <>
      <EditUser user={userEdit} closeCallback={closeModalCallback}/>
      <OrgUsersAccessExpiration open={allAccessExpirations} closeCallback={accessModalCallback} orgId={orgId} defaultDate={admin.access_expiration}/>
      <div style={modalBlur}>
        <details open={open}>
          <summary>Registered User Management</summary>
          <nav>
            {!filteredUsersFlag && <>
              <button type='button' onClick={pageDown} disabled={downDisabled}>Previous Page</button>
              <button type='button' onClick={pageUp} disabled={upDisabled}>Next Page</button>
              <span>{showingOf}</span>
            </>}
            <form onSubmit={searchUsers}>
              <label htmlFor='userSearch'>Search users by email</label>
              <input id='userSearch'type='text' value={userSearchText} onChange={handleUserSearchText} placeholder='Search for users by email' />
              <div className={styles.buttonContainer}>
                <button type='submit'>Search Users</button>
                <button type='button' onClick={clearSoughtUsers}>Clear Search</button>
              </div>
            </form>
          </nav>
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Display Name</th>
                <th>Role</th>
                <th>Access Expiration</th>
                <th>Last Seen</th>
                <th>Remove</th>
                <th>Edit User</th>
              </tr>
            </thead>
            <tbody>
              {userInfo}
            </tbody>
          </table>
          <button type='button' onClick={handleDeleteAllOrgUsers}>Delete Org&apos;s Regular Users</button>
          <button type='button' onClick={handleAllUserExpirations}>Change All Access Expirations</button>
          <button type='button' onClick={handleUserExport}>Export Users</button>
        </details>
      </div>
    </>
  );
}

OrgUserTable.propTypes = propTypes;
