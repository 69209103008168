import React from 'react';

const Thoracic = () => (
  <>
    <h1>Thoracic Methodology</h1>
    <br/>
    <h2>THORACIC CANCERS RESEARCH QUESTION:</h2>
    <p>What is the role of integrative therapies in the clinical management of thoracic cancer and survivorship?</p>
    <br />
    <h2>INCLUSION CRITERIA:</h2>
    <ul>
      <li>English language article</li>
      <li>Human</li>
      <li>
        All thoracic cancer tumor types/neoplastic cases: lung, gastric and/or esophageal or pre-dominant focus of trial (minimum 25% thoracic cancer cases)
      </li>
      <li>KNOW beta topic -- contains data for all years</li>
      <li>
      All outcomes including: Overall Survival (OS), Progression Free Survival (PFS), Quality of Life (QoL), and side effect management.
      </li>
      <li>Females and males</li>
      <li>All nutraceuticals (including oral supplements, dietary, intravenous, injectable, topical)</li>
      <li>Diet therapy (Mediterranean, ornish, ketogenic, vegetarian, etc.)</li>
      <li>Physical therapies (yoga, exercise, acupuncture, massage)</li>
      <li>Mental emotional therapies</li>
      <li>Secondary prevention (articles that report data to prevent cancer from recurring)</li>
      <li>Systematic Review articles and meta analyses with human data</li>
    </ul>
    <br />
    <h2>EXCLUSION CRITERIA:</h2>
    <ul>
      <li>Clinical guidelines</li>
      <li>Non- English language article</li>
      <li>Non- human (eg. in vitro or in vivo non-human animal)</li>
      <li>Non-neoplasms</li>
      <li>Primary prevention (articles that report data to prevent cancer from occurring)</li>
      <li>Drug only trials (no metformin, LDN, DCA, IPT, light therapy, metronomic chemo)</li>
      <li>Prevalence/usage of CAM</li>
      <li>Conference proceedings, trial postings without results; interim analyses</li>
    </ul>
    <br />
    <h2>KNOW SEARCH METHODOLOGY – INTEGRATIVE TREATMENT IN THORACIC CANCERS:</h2>
    <p>
        The search strategies were developed and tested through an iterative process by an experienced medical information specialist in consultation with the review team. The project was broken down into multiple topics and the searches performed in a staged fashion: Vitamin D (February 7, 2015), Complementary and Alternative Medicine (February 23), Natural Health Products (June 22), Mind/Body (June 26), and Diet/Nutrition (July 10).
    </p>
    <br/>
    <p>
        Using the OVID platform, we searched Ovid MEDLINE®, Ovid MEDLINE® In-Process & Other Non-Indexed Citations, Embase, and Allied and Complementary Medicine (AMED). We also searched the Cochrane Library on Wiley (including the Cochrane Database of Systematic Reviews, DARE, CENTRAL and HTA databases).
    </p>
    <br/>
    <p>
        Strategies utilized a combination of controlled vocabulary (e.g., “Neoplasms”, “Surgical Procedures, Operative”, “Vitamin D”) and keywords (e.g., cancer, surgery, cholecalciferol). Vocabulary and syntax were adjusted across the databases. With the exception of the Vitamin D search, results were limited to the publication years 2000 to the present. Human or unindexed records and opinion pieces were removed from the results of all searches where possible.
    </p>
    <br/>
    <p>The search will be updated on a scheduled basis and relevant citations incorporated.</p>
  </>
);

export default Thoracic;
