import React from 'react';

const Neuropathy = () => (
  <>
    <h1>Neuropathy Methodology</h1>
    <br/>
    <h2>NEUROPATHY RESEARCH QUESTION:</h2>
    <p>What is the role of integrative therapies in the clinical management of cancer treatment related peripheral neuropathy in cancer patients and survivors?</p>
    <br />
    <h2>INCLUSION CRITERIA:</h2>
    <ul>
      <li>English language</li>
      <li>Human experimental</li>
      <li>
        All cancer tumor types and survivors with neuropathy related to the treatment of cancer (ie. by surgery, chemotherapy, or radiation)
      </li>
      <li>KNOW beta topic -- contains data for all years</li>
      <li>
        All outcomes including: Overall Survival (OS), Progression Free Survival (PFS), Recurrence, Quality of Life (QoL), and side effect management
      </li>
      <li>Females and males</li>
      <li>
        All nutraceuticals (including oral supplements, botanicals, homeopathy, dietary, intravenous, injectable, topical), Diet therapy (Mediterranean, ornish, vegetarian, etc.), Acupuncture and oriental medicine, Mind body supportlit, Exercise therapy, Yoga therapy, Prayer, Hypnotherapy, Light therapy, Hydrotherapy. (Natural agent complexed to pharma ok.)
      </li>
      <li>Systematic Review articles and meta analyses with human data</li>
      <li>Clinical guidelines</li>
    </ul>
    <br />
    <h2>EXCLUSION CRITERIA:</h2>
    <ul>
      <li>Non- English language article</li>
      <li>Non- human (eg. in vitro or in vivo non-human animal)</li>
      <li>Non-cancer or non-cancer treatment related neuropathy (ie. diabetic)</li>
      <li>Any neuropathy type NOT resulting from cancer or treatment of cancer</li>
      <li>Drug only trials (no metformin, LDN, DCA, IPT, metronomic chemo etc.)</li>
      <li>Prevalence/usage of CAM</li>
      <li>Conference proceedings</li>
      <li>Trial postings without results; interim analyses</li>
    </ul>
    <br/>
    <p>
      NOTE: A word about primary prevention studies. While our search and criteria do include primary prevention studies, at this time we are working to only summarize data for secondary prevention ie. Studies conducted on patients after diagnosis with cancer and survivors and we are NOT summarizing studies on the prevention of cancer from occurring.
    </p>
    <br />
    <h2>KNOW SEARCH METHODOLOGY – INTEGRATIVE TREATMENT OF CHEMOTHERAPY-INDUCED PERIPHERAL NEUROPATHY:</h2>
    <p>
        Using the dietary supplement subset available in both PubMed and MEDLINE as our base, we created a line-by-line version of this filter for use in Embase. With the help of naturopathic doctors on the team, we identified additional relevant vocabulary that we incorporated into the filter. This filter was then paired with the pertinent vocabulary for chemotherapy-induced peripheral neuropathy.
    </p>
    <br/>
    <p>
        We ran searches in Ovid MEDLINE®, Ovid MEDLINE® In-Process ∓ Other Non-Indexed Citations and Embase Classic+Embase on October 15 and 17, 2015. Strategies utilized a combination of controlled vocabulary (e.g., Neoplasms, Peripheral Nervous System Disease, Platinum Compounds) and keywords (e.g., cancer, CIPN, cisplatin). Vocabulary and syntax were adjusted across the databases. No language or date restrictions were applied but animal-only and opinion-pieces were removed from the results.
    </p>
    <br/>
    <p>The search will be updated on a scheduled basis and relevant citations incorporated.</p>
    
  </>
);

export default Neuropathy;
