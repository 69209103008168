import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styles from './App.css';
import Header from './header/Header';
import Home from './home/Home';
import Methodology from './methodology/Methodology';
import Studies from './studies/Studies';
import ClinicalPearls from './clinicalPearls/ClinicalPearls';
import Interactions from './interactions/Interactions';
import Login from './login/Login';
import ForgotPassword from './forgotPassword/ForgotPassword';
import Donate from './donate/Donate';
import Feedback from './feedback/Feedback';
import SingleResult from './singleResult/SingleResult';
import AddPearl from './addPearl/AddPearl';
import TermsOfUse from './termsOfUse/TermsOfUse';
import Signup from './signup/Signup';
import OrgAdmin from './orgAdmin/OrgAdmin';
import KnowAdmin from './knowAdmin/KnowAdmin';
import PasswordReset from './passwordReset/PasswordReset';
import NoMatchPage from './404/NoMatchPage';
import Footer from './footer/Footer';
import Chaport from './chaport/Chaport';

export default function App() {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentWrap}>
        <Router>
          <Header />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/methodology' component={Methodology} />
            <Route path='/studies' component={Studies} />
            <Route exact path='/pearls' component={ClinicalPearls} />
            <Route exact path='/interactions' component={Interactions} />
            <Route exact path='/login' component={Login} />
            <Route exact path='/forgot-password' component={ForgotPassword} />
            <Route exact path='/reset-password' component={PasswordReset} />
            <Route exact path='/donate' component={Donate} />
            <Route exact path='/feedback' component={Feedback} />
            <Route exact path='/shared' component={SingleResult} />
            <Route exaxt path='/add-pearl' component={AddPearl} />
            <Route exact path='/terms-of-use' component={TermsOfUse} />
            <Route exact path='/signup' component={Signup} />
            <Route exact path='/org-admin' component={OrgAdmin} />
            <Route exact path='/know-admin' component={KnowAdmin} />
            <Route component={NoMatchPage} />
          </Switch>
          <Footer />
          <Chaport />
        </Router>
      </div>
    </div>
  );
}
