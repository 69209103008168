import React, { useState } from 'react';
import { IoIosInformationCircleOutline, IoIosCloseCircle } from 'react-icons/io';
import styles from './searchInfo.css';

export default function SearchInfo() {
  const [modalState, setModalState] = useState(false);

  const openModal = () => {
    setModalState(true);
  };
  const closeModal = event => {
    event.stopPropagation();
    event.preventDefault();
    setModalState(false);
  };

  const openCloseModal = modalState ? styles.showModal : styles.hideModal;

  return (
    <>
      <IoIosInformationCircleOutline onClick={openModal}/>
      <div className={openCloseModal}>
        <div className={styles.modal}>
          <button type='button' onClick={closeModal}><IoIosCloseCircle /></button>
          <p>
            There are two main components to refining a search: the text input, and the use of filters.
            <br />
            <br />
            Text input: Think of this like a google search on studies. If a single term is entered, all the fields of a study will be searched for the term, and the found term will be highlighted. The search is not case sensitive.
            <br />
            <br />
            A term is checked for synonyms before studies are searched. For example, if 'iron' is searched, any study with the words iron, ferrous sulphate, ferrous gluconate, or iron sucrose will be found. The synonyms are OR'ed togeher, so if a study does not contain the word 'iron', but contains 'ferrous sulphate', the study will match.
            <br />
            <br />
            Phrases: To search for a phrase or multi-word term, use double quotes. For example, "green tea" will ensure that returned studies will have the word green followed by the word tea. The word order for a phrase matters: searching "tea green" will not match studies with "green tea".
            <br />
            <br />
            Multiple terms or phrases: There is no need to separate terms and phrases with commas. A search with multiple terms or phrases will return studies that have ALL the sought after terms. For example, searching 'estrogen "green tea"' will search for studies that have both estrogen and green tea in the study. The terms and phrases are effectively AND'ed together. The order of multiple terms and phrases does not matter. Again, within a single phrase, the word order matters.
            <br />
            <br />
            Filters: 
            <br />
            Single filters: The filters look at a specific section of a summarized study to decide whether it is relevant to a search. For example, all summarized studies have a section for describing side effects; using the side effects filter will ensure the side effect section specifically mentions a particular side effect.
            <br />
            <br />
            If a filter has multiple options selected, a study needs to have only one matching option to be included in the results. For example, if the Side Effects filter has the 'Insomnia' and 'Bruising' options selected, a study needs to have either insomnia OR bruising in the side effects section to be included as a result. Effectively, the options within a single filter are OR'ed together.
            <br />
            <br />
            Multiple filters: If a search uses multiple filters, the different filters will be AND'ed together. Within any particular filter, the options will still be OR'ed together. For example, if a search has the 'Cystitis' option selected in the Side Effects filter, and the 'Acupuncture' option selected in the Natural Therapy Agents filter, a study will need both cystitis and acupuncture listed in their respective sections. If instead 'Cystitis' is selected for the Side Effect filter, and the Natural Therapy Agents filter has both 'Acupuncture' and 'Melatonin' options selected, a study will need to have Cystitis in its side effect section and either acupuncture or melatonin in the natural therapy agents section.
            <br />
            <br />
            Text and filters together: Filters can be used in conjuction with a text search. First, studies will be sought with the search terms and phrases. The filters are then applied to those studies that match the text search. For example, a search with '"willow bark" america' as the search text and 2016, 2017, 2018, and 2019 options selected for the Year Published filter will ensure that a study has "willow bark" and America somewhere in the study, and that the study was published in either 2016, 2017, 2018, or 2019.
            <br />
            <br />
          </p>
        </div>
      </div>
    </>
  );
}
