import React from 'react';
import Welcome from './Welcome';
import Video from './Video.jsx';
import Explanation from './Explanation.jsx';
import Sidebar from './sidebar/Sidebar.jsx';
import './Home.css';

const Home = () => (
  <main className="grid-x grid-container">
    <div className="cell small-12 medium-8">
      <Welcome />
      <Video />
      <Explanation />
    </div>
    <div className="cell small-12 medium-auto">
      <Sidebar />
    </div>
  </main>
);

export default Home;
