import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { resultItem, resultDetails } from './results.css';
import ResultUtilityButtons from './resultUtilityButtons/ResultUtilityButtons.jsx';
import highlightText from '../../../utils/highlightText';
import { tagHeaderToFilterDictionary } from '../../../utils/resultsSectionSetArray';
import { summaryTagHeadersDict } from '../../../utils/tagHeadersDicts';
import { sysReviewFields, clinicalTrialFields, observationalFields, caseReportFields, unknownFields, microtagFields, ignoreEntries } from '../../../utils/displayKeys';
// import styles from './results.css';
                
SummarizedResultItem.propTypes = {
  study: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  filteredKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default function SummarizedResultItem({ study, isOpen, filteredKeys }) {
  // const { docType, studyTypeInt, summaryTitle, year, link } = study;
  const { docType, studyTypeInt, summaryTitle, year, outcomeResults, link } = study;
  const displayOutcomes = (outcomeResults => {
    const displayOutcomesObj = { positive: '', negative: '', neutral: '' };
    if(!outcomeResults) return displayOutcomesObj;
    if(outcomeResults.includes('Positive')) displayOutcomesObj.positive = <span title="Positive Outcome(s)" style={{ backgroundColor: 'green', opacity: '0.8', display: 'inline-block', width: '1.4em', borderRadius: '4px', margin: '2px' }}>&nbsp;</span>; // <span style={{ verticalAlign: 'center' }}><GoPrimitiveDot style={{ color: 'green' }}/></span>; //<span className={positive}>•&nbsp;</span>;
    if(outcomeResults.includes('Neutral')) displayOutcomesObj.neutral = <span title="Neutral Outcome(s)" style={{ backgroundColor: 'goldenrod', opacity: '0.8', display: 'inline-block', width: '1.4em', borderRadius: '4px', margin: '2px' }}>&nbsp;</span>; // <span style={{ verticalAlign: 'center' }}><GoPrimitiveDot style={{ color: 'orange' }}/></span>; //<span className={neutral}>•&nbsp;</span>;
    if(outcomeResults.includes('Negative')) displayOutcomesObj.negative = <span title="Negative Outcome(s)" style={{ backgroundColor: 'red', opacity: '0.8', display: 'inline-block', width: '1.4em', borderRadius: '100%', margin: '2px' }}>&nbsp;</span>; // <span style={{ verticalAlign: 'center' }}><GoPrimitiveDot style={{ color: 'red' }}/></span>; //<span className={negative}>•&nbsp;</span>;
    return displayOutcomesObj;
  })(outcomeResults);

  const highlightTagHeader = tagHeader => {
    if(!filteredKeys) return;
    if(filteredKeys.includes(tagHeaderToFilterDictionary[tagHeader]))
      return highlightText(`<highlighter>${tagHeader}</highlighter>`, 'highlighter');
    return tagHeader;
  };

  const studyTableRows = [];
  const populateRows = field => {
    if(!field) return;

    const tagHeader = summaryTagHeadersDict[field];
    const entry = study[field];
    if(entry && !ignoreEntries.includes(entry)) {
      if(!(field === 'link')) {
        studyTableRows.push(
          <tr key={field}>
            <td>
              <strong>{highlightTagHeader(tagHeader)}</strong>
            </td>
            <td>
              {highlightText(entry, 'highlighter')}
            </td>
          </tr>
        );
      } else {
        studyTableRows.push(
          <tr key={field}>
            <td>
              <strong>{tagHeader}</strong>
            </td>
            <td>
              {link.split(',').map((link, i) => <Fragment key={i}><a href={link.trim()} target='_blank' rel="noopener noreferrer">{link.trim()}</a><br /></Fragment>)}
            </td>
          </tr>
        );
      }
    }
  };

  switch(studyTypeInt) {
    case 1:
      if(docType && docType === 'Summary') sysReviewFields.forEach(populateRows);
      else microtagFields.forEach(populateRows);
      break;
    case 2:
      if(docType && docType === 'Summary') clinicalTrialFields.forEach(populateRows);
      else microtagFields.forEach(populateRows);
      break;
    case 3:
      if(docType && docType === 'Summary') observationalFields.forEach(populateRows);
      else microtagFields.forEach(populateRows);
      break;
    case 4:
      if(docType && docType === 'Summary') caseReportFields.forEach(populateRows);
      else microtagFields.forEach(populateRows);
      break;
    case 5:
      unknownFields.forEach(populateRows);
      break;
    default:
      break;
  }

  const highlightedTitle = highlightText(summaryTitle, 'highlighter');
  // const firstLetter = docType.slice(0, 1);
  // const studyTypeBadge = firstLetter !== 'N' ? <span title={firstLetter === 'S' ? 'Full Summary' : 'Microtag'} className={styles.studyTypeBadge}>{docType.slice(0, 1)}</span> : '';
  const studyTypeText = docType !== 'None' ? <span style={{ fontWeight: '400' }}>[{docType}]</span> : '';
  const { positive, neutral, negative } = displayOutcomes;
  return (
    <>
      <details className={resultItem} open={isOpen}>
        {<summary><strong>{highlightedTitle} ({year}) {positive}{neutral}{negative} {studyTypeText}</strong></summary>}
        {/* {<summary><strong>{highlightedTitle} ({year}) {studyTypeText}</strong></summary>} */}
        <ResultUtilityButtons study={study} />
        <table className={resultDetails}>
          <tbody>
            {studyTableRows}
          </tbody>
        </table>
      </details>
    </>
  );
}
