import { request } from './request';

export const searchSummariesWithFilter = filterOptions => 
  request('/summaries/search', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'summarized'
    }));

export const searchSupportLitWithFilter = filterOptions =>
  request('/support-lit/search', 'POST', filterOptions)
    .then(res => ({
      res,
      promiseId: 'support'
    }));

export const getSingleSummarizedStudy = id => request(`/summaries/${id}`, 'GET');
export const getSingleSupportLit = id => request(`/support-lit/${id}`, 'GET');

export const getAllSummaries = () => request('/summaries', 'GET')
  .then(res => ({
    res,
    promiseId: 'summarized'
  }));

export const getAllSupportLit = () => request('/support-lit', 'GET')
  .then(res => ({
    res,
    promiseId: 'support'
  }));
