/* eslint-disable quotes */
import React, { Children, cloneElement, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import defaultStyles from './modalGroup.css';

const propTypes = {
  modalGroupArray: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string.isRequired,
    ).isRequired,
  ).isRequired,
  setCallback: PropTypes.func.isRequired,
  includeAllOption: PropTypes.bool,
  styles: PropTypes.object,
  children: PropTypes.node
};

const ModalGroup = ({ modalGroupArray, setCallback, includeAllOption = false, styles = defaultStyles, children = null }) => {
  const initializeState = () => {
    return modalGroupArray.reduce((initialState, modalArray) => {
      const [modalLabel, allOption] = modalArray;
      const trueOptions = includeAllOption ? [allOption] : [];
      return { ...initialState, [modalLabel]: trueOptions };
    }, {});
  };
  const modalGroupStyling = styles.modalGroup;
  const [openModalGroupBool, setOpenModalGroupBool] = useState(false);
  const [groupState, setGroupState] = useState(initializeState);

  const initialRender = useRef(true);
  useEffect(() => {
    if(!initialRender.current) {
      setCallback(groupState);
    } else initialRender.current = false;
  }, [groupState]);

  const handleModalOptionChange = selectedModalOptions => setGroupState({ ...groupState, ...selectedModalOptions });

  const openCloseCallbacks = {
    openCallback: () => setOpenModalGroupBool(true),
    closeCallback: () => setOpenModalGroupBool(false)
  };

  const modals = modalGroupArray.map(array => (
    <Modal optionsArray={array} key={array[0]} openModalGroupBool={openModalGroupBool} modalFunctions={openCloseCallbacks} setCallback={handleModalOptionChange} includeAllOption={includeAllOption} styles={styles} />
  ));

  const childModals = Children.toArray(children);
  const modalWithNewProps = childModals.map(modal => {
    return cloneElement(modal, { 
      ...modal.props,
      modalFunctions: openCloseCallbacks,
      setCallback: handleModalOptionChange,
      openModalGroupBool,
      styles
    });
  });

  return (
    <section className={modalGroupStyling}>
      {modals}
      {modalWithNewProps}
    </section>
  );
};

ModalGroup.propTypes = propTypes;

export default ModalGroup;
