// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3PZZFYb1G4DQ0PDMYJiLNW {\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 100vh;\r\n}\r\n\r\n._1HsAtO2_imIDZZ2LIhYStR {\r\n  flex: 1;\r\n  font-weight: inherit;\r\n}\r\n", "",{"version":3,"sources":["App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,OAAO;EACP,oBAAoB;AACtB","file":"App.css","sourcesContent":[".pageContainer {\r\n  display: flex;\r\n  flex-direction: column;\r\n  min-height: 100vh;\r\n}\r\n\r\n.contentWrap {\r\n  flex: 1;\r\n  font-weight: inherit;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"pageContainer": "_3PZZFYb1G4DQ0PDMYJiLNW",
	"contentWrap": "_1HsAtO2_imIDZZ2LIhYStR"
};
module.exports = exports;
