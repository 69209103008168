import React from 'react';

const Curcumin = () => (
  <>
    <h1>Curcumin Methodology</h1>
    <br/>
    <h2>CURCUMIN RESEARCH QUESTION:</h2>
    <p>What is the role of curcumin in the clinical management of cancer and survivorship?</p>
    <br />
    <h2>INCLUSION CRITERIA:</h2>
    <ul>
      <li>English language article</li>
      <li>Human</li>
      <li>All tumor types/neoplastic cases [minimum of 25% study population with cancer]</li>
      <li>KNOW beta topic -- contains data for all years</li>
      <li>
        All outcomes including: Overall Survival (OS), Progression Free Survival (PFS), Quality of Life (QoL), and side effect management.
      </li>
      <li>Females and males</li>
      <li>All forms of curcumin preparations (including oral supplements, dietary, intravenous, injectable, topical)</li>
      <li>Secondary prevention (articles that report data to prevent cancer from recurring)</li>
      <li>Systematic Review articles and meta analyses with human data</li>
      <li>Clinical guidelines</li>
    </ul>
    <br />
    <h2>EXCLUSION CRITERIA:</h2>
    <ul>
      <li>Non- English language article</li>
      <li>Non- human (eg. in vitro or in vivo non-human animal)</li>
      <li>Non-neoplasms</li>
      <li>Prevalence/usage of curcumin studies</li>
      <li>Conference proceedings, trial postings without results; interim analyses</li>
    </ul>
    <br />
    <h2>KNOW SEARCH METHODOLOGY – CURCUMIN IN CANCER TREATMENT TRIALS:</h2>
    <p>
        The search strategy was developed and tested through an iterative process by an experienced medical information specialist in consultation with the review team. The database searches were executed on October 17, 2015. Using the OVID platform, we searched Ovid MEDLINE®, Ovid MEDLINE® In-Process & Other Non-Indexed Citations, Embase, and Allied and Complementary Medicine (AMED). We also searched the Cochrane Library on Wiley (including the Cochrane Database of Systematic Reviews, DARE, CENTRAL and HTA databases).
    </p>
    <br/>
    <p>
        Strategies utilized a combination of controlled vocabulary (e.g., “Curcumin”, “Curcuma “, “Neoplasms”) and keywords (e.g., turmeric, golden seal, cancer). Vocabulary and syntax were adjusted across databases. When possible, results were limited to human or unindexed records and opinion pieces were removed from the results.
    </p>
    <br/>
    <p>The search will be updated on a scheduled basis and relevant citations incorporated.</p>
    
  </>
);

export default Curcumin;
