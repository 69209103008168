// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1SL3You8JsnbdsNzE_pWuw{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  padding-left: 2em;\r\n  padding-right: 2em;\r\n}\r\n\r\n._1Misa2j2F9Vh5gDkXDuXng {\r\n  font-weight: bold;\r\n  margin-top: 1em;\r\n  margin-bottom: 1em;\r\n}\r\n", "",{"version":3,"sources":["termsOfUse.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB","file":"termsOfUse.css","sourcesContent":[".termsContainer{\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: center;\r\n  padding-left: 2em;\r\n  padding-right: 2em;\r\n}\r\n\r\n.termHeader {\r\n  font-weight: bold;\r\n  margin-top: 1em;\r\n  margin-bottom: 1em;\r\n}\r\n"]}]);
// Exports
exports.locals = {
	"termsContainer": "_1SL3You8JsnbdsNzE_pWuw",
	"termHeader": "_1Misa2j2F9Vh5gDkXDuXng"
};
module.exports = exports;
