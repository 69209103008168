// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3SEq3uProfGv8tT8uwZhJN {\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: center;\r\n  top: 50%;\r\n  left: 50%;\r\n  position: fixed;\r\n  overflow: auto;\r\n  z-index: 3;\r\n  margin: 5% auto;\r\n  padding: 1rem;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  min-width: 7em;\r\n  width: auto;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.q5dfmnMKdURiKr6we_-gu {\r\n  position: absolute;\r\n  z-index: 2;\r\n  width: 100vw; \r\n  height: 100vh;\r\n  background-color: #bbbaba6c;\r\n}", "",{"version":3,"sources":["OrgUsersAccessExpiration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,cAAc;EACd,UAAU;EACV,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,2BAA2B;AAC7B","file":"OrgUsersAccessExpiration.css","sourcesContent":[".accessModal {\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: center;\r\n  top: 50%;\r\n  left: 50%;\r\n  position: fixed;\r\n  overflow: auto;\r\n  z-index: 3;\r\n  margin: 5% auto;\r\n  padding: 1rem;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  min-width: 7em;\r\n  width: auto;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.backgroundDiv {\r\n  position: absolute;\r\n  z-index: 2;\r\n  width: 100vw; \r\n  height: 100vh;\r\n  background-color: #bbbaba6c;\r\n}"]}]);
// Exports
exports.locals = {
	"accessModal": "_3SEq3uProfGv8tT8uwZhJN",
	"backgroundDiv": "q5dfmnMKdURiKr6we_-gu"
};
module.exports = exports;
