import React from 'react';
import SupportLitResultItem from './SupportLitResultItem';
import styles from './results.css';
import PropTypes from 'prop-types';

const propTypes = {
  supportLit: PropTypes.shape({
    results: PropTypes.arrayOf(PropTypes.object.isRequired),
    fullCount: PropTypes.number
  }).isRequired,
  searchFlag: PropTypes.bool.isRequired,
  // currentPage: PropTypes.number.isRequired
};

export default function SupportLitResults({ supportLit, 
  // searchFlag, 
  // currentPage 
}) {

  const supportLitItems = supportLit.results.map(lit => (
    <li key={lit._id}>
      <SupportLitResultItem supportLitItem={lit} isOpen={false} />
    </li>
  ));

  // const calcSupportLitCount = () => {
  //   const countMin = currentPage * 20 - 19;
  //   let countMax = countMin + 19;
  //   if(countMax > supportLit.fullCount) countMax = supportLit.fullCount;
  //   if(countMin > supportLit.fullCount) {
  //     return 'No more results';
  //   }
  //   return `${countMin} - ${countMax} of ${supportLit.fullCount}`;
  // };

  return (
    <div>
      {/* Support lit Header For pagination: */}
      {/* <h2><strong>Support Literature{searchFlag && ` (${calcSupportLitCount()})`}:</strong></h2> */}

      {/* Support Lit Header for no pagination: */}
      {/* <h2><strong>Support Literature{searchFlag && ` (${supportLit.fullCount})`}</strong></h2> */}

      {!!supportLitItems.length && <details open={false}>
        <summary className={`${styles.summarySection} ${styles.supHeader}`}>
          <span style={{ backgroundColor: '#68ae9f', color: '#68ae9f', display:'inline-block' }}>
                &nbsp;
          </span>
          <span style={{ marginLeft: '2px' }}>
            <strong>Support Literature</strong>
          </span>
        </summary>
        {supportLitItems}
      </details>}
    </div>
  );
}

SupportLitResults.propTypes = propTypes;
