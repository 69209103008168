import React from 'react';
import styles from './donate.css';
const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef

export default function Donate() {

  return (
    <div className={styles.donate}>
      <p className="h5">Donate to the OncANP to support the KNOW Project</p>
      <a href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=TQ4LGZ45ZZJ64&source=url' rel='noopener noreferrer' target='_blank'>
        <img src={`${ASSETS_PATH}/donate.gif`}></img>
      </a>
      <p><strong>KNOW Licensing Opportunities</strong></p>
      <p>Interested in licensing KNOW for your organization?<br />
      Please email us: <a href="mailto:info@knowoncology.org?subject=Sponsorship%20Inquiry">info@knowoncology.org</a> 
      </p>
    </div>
  );
}
