// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".qJ_POMZm_aeD6gi-11VJq {\r\n  grid-column: 1 / 3;\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  margin-bottom: 1em;\r\n}", "",{"version":3,"sources":["OrgUserTable.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB","file":"OrgUserTable.css","sourcesContent":[".buttonContainer {\r\n  grid-column: 1 / 3;\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  margin-bottom: 1em;\r\n}"]}]);
// Exports
exports.locals = {
	"buttonContainer": "qJ_POMZm_aeD6gi-11VJq"
};
module.exports = exports;
