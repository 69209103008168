import { request } from './request';

export const getAdminOrg = id => {
  return request(`/organizations/${id}`, 'GET');
};

export const getOrgUsers = id => {
  return request(`/organizations/${id}/users`, 'GET');
};

export const removeFromOrg = (userId, orgId) => {
  return request(`/auth/${userId}?userOrgId=${orgId}`, 'DELETE');
};

export const deleteUsers = usersToDelete => {
  return request('/auth/bulk-delete', 'DELETE', { targetUsers: usersToDelete });
};

export const inviteUsers = (usersToSignUp, adminOrganization, orgExpiration, newUsersRole) => {
  return request('/invitations/bulk-invite', 'POST', { 
    signupList: usersToSignUp,
    adminOrganization,
    orgExpiration,
    newUsersRole
  });
};

export const getOrgInvites = orgId => {
  return request(`/invitations/org/${orgId}`, 'GET');
};

export const deleteUserInvitation = (email, orgId) => {
  return request('/invitations', 'DELETE', { email, orgId });
};

export const resendInvitation = (email, orgId) => {
  return request('/invitations/resend', 'POST', { email, orgId });
};

export const patchUser = (editedUserInfo, userId) => {
  return request(`/auth/${userId}/admin`, 'PATCH', editedUserInfo);
};

export const patchAllRegularUsers = (editedInfo, orgId) => {
  return request(`/organizations/org-users/${orgId}`, 'PUT', editedInfo);
};
