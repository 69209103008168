import { request } from './request';

export const orgSignup = orgInfo => {
  return request('/organizations/create-and-invite', 'POST', orgInfo);
};

export const signupGuest = guestInfo => {
  return request('/auth/signup-guest', 'POST', guestInfo);
};

export const getAllOrgs = () => {
  return request('/organizations/', 'GET');
};

export const patchOrg = (editedOrgInfo, orgId) => {
  return request(`/organizations/${orgId}`, 'PUT', editedOrgInfo);
};
