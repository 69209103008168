// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "section[id=video] h4 {\r\n    align-self: flex-start;\r\n    font-weight: bold;\r\n    margin-bottom: 1em;\r\n  }\r\n\r\n._3HTvYwHOT6KpA2Iwo8blkC {\r\n  position: relative;\r\n  padding-bottom: 53.25%; /* 16:9 */\r\n  height: 0;\r\n}\r\n\r\n._3HTvYwHOT6KpA2Iwo8blkC iframe {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  section[id=video] {\r\n    width: auto;\r\n  }\r\n\r\n    section[id=video] iframe {\r\n      width: 100%;\r\n      height: auto;\r\n    }\r\n\r\n  h4 {\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  ._3HTvYwHOT6KpA2Iwo8blkC {\r\n    padding-bottom: 65%;\r\n  }\r\n}", "",{"version":3,"sources":["Video.css"],"names":[],"mappings":"AACE;IACE,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;EACpB;;AAGF;EACE,kBAAkB;EAClB,sBAAsB,EAAE,SAAS;EACjC,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,WAAW;EAMb;;IAJE;MACE,WAAW;MACX,YAAY;IACd;;EAGF;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","file":"Video.css","sourcesContent":["section[id=video] {\r\n  h4 {\r\n    align-self: flex-start;\r\n    font-weight: bold;\r\n    margin-bottom: 1em;\r\n  }\r\n}\r\n\r\n.videoWrapper {\r\n  position: relative;\r\n  padding-bottom: 53.25%; /* 16:9 */\r\n  height: 0;\r\n}\r\n\r\n.videoWrapper iframe {\r\n  position: absolute;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  section[id=video] {\r\n    width: auto;\r\n\r\n    iframe {\r\n      width: 100%;\r\n      height: auto;\r\n    }\r\n  }\r\n\r\n  h4 {\r\n    text-align: center;\r\n  }\r\n}\r\n\r\n@media (max-width: 350px) {\r\n  .videoWrapper {\r\n    padding-bottom: 65%;\r\n  }\r\n}"]}]);
// Exports
exports.locals = {
	"videoWrapper": "_3HTvYwHOT6KpA2Iwo8blkC"
};
module.exports = exports;
