import { login, verify, logout } from '../../services/auth';


export const SET_USER_LOADING = 'SET_USER_LOADING';
export const setUserLoading = () => ({
  type: SET_USER_LOADING
});

export const SET_USER = 'SET_USER';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const setUser = (email, password) => dispatch => {
  dispatch(setUserLoading());
  return login(email, password)
    .then(user => {
      dispatch({
        type: SET_USER,
        payload: user
      });
    })
    .catch(err => {
      if(err) {
        dispatch({
          type: SET_AUTH_ERROR,
          payload: err
        });
      }
    });
};

export const verifyUser = () => dispatch => {
  dispatch(setUserLoading());
  return verify()
    .then(user => {
      dispatch({
        type: SET_USER,
        payload: user
      });
    })
    .catch(() => {
      dispatch({
        type: SET_USER,
        payload: {}
      });
    });
};

export const LOGOUT_USER = 'LOGOUT_USER';
export const logoutUser = () => dispatch => {
  dispatch(setUserLoading());
  return logout()
    .then(() => {
      dispatch({
        type: LOGOUT_USER
      });
    });
};
