import React from 'react';
import './Explanation.css';

const Explanation = () => (
  <section id='explanation'>
    <p style={{ 'textDecoration': 'underline' }}>Here&apos;s what we did:</p>
    <ul>
      <li>
      We asked the question &quot;What is the role of integrative therapies in the clinical management of people with cancer?&quot;
      </li>
      <li>
      We searched Pub Med and Embase to harvest worldwide data on integrative oncology with specific focus on nutraceuticals and diet.
      </li>
      <li>
      We searched and parallel screened the relevant data.
      </li>
      <li>
      Studies were tagged for tumor type, side effects, natural therapy and conventional therapy to make it highly searchable. If an open text is available, the link is provided. 
      </li>
      <li>
      We created an expanded PICO template (population, intervention, comparator, outcome) and extract the data to provide a summary. For studies not yet summarized, we provide the citation or a brief summary.
      </li>
      <li>
      Search results are listed according to level of evidence with systematic reviews and meta-analysis listed first, then clinical trials, observational studies, and lastly case reports.
      </li>
      <li>
      KNOW includes data on ALL tumor types, cancer-related conditions, and natural therapies for the years 2010-2020. For the topics of breast cancer, thoracic cancer, neuropathy, and curcumin, KNOW holds all published data up to 2020.
      </li>
      <li>
      In comparison analysis by topic with PubMed, KNOW holds significantly more human data in integrative oncology. Click <a href="assets\KNOW Data Quality Poster OncANP 2020 FINAL.pdf" target="_blank">here</a> for a poster presentation on KNOW’s quality assurance.
      </li>
    </ul>

    <p style={{ 'textDecoration': 'underline' }}>Here&apos;s why:</p>
    <ul>
      <li>
      To provide clinicians, researchers, and students the ability to access human level integrative medicine data for best education, safety, and clinical decision-making.</li>
      <li>
      To allow colleagues to collaborate on research and publication projects or to create patient resources.
      </li>
      <li>
      To see patient education resources created using data in KNOW, <a href="https://oncanp.org/patient-resources/" target="_blank" rel="noopener noreferrer">click here</a>.
      </li>
    </ul>

    <p style={{ 'textDecoration': 'underline' }}>Our goals:</p>
    <ul>
      <li>
      Improve knowledge of integrative oncology research by clinicians
      </li>
      <li>
      Support evidence informed patient care
      </li>
      <li>
      Inspire collaboration
      </li>
    </ul>

    <div className="whatyouget">
      <p style={{ 'textDecoration': 'underline' }}>Here&apos;s what you get:</p>
      <ol>
        <li>
          <span>Efficiency: </span>Currently, enormous energy is spent to distill current literature.
        </li>
        <li>
          <span>Staying up-to-date: </span>The volume of research in integrative oncology is ever increasing and nearly impossible to stay abreast of; the KNOW team of researchers and medical librarians will keep the website current and can access full text articles and international studies that the average clinician cannot easily get.
        </li>
        <li>
          <span>Knowledge sharing with fellow providers: </span>Relationships between conventional and integrative providers improves when clinicians share evidence in their communications. <i>KNOW research summaries and references can be copied/pasted into your documents.</i>
        </li>
        <li>
          <span>Evidence-informed practice: </span>Making evidence-informed decisions requires easy access to relevant research. KNOW puts this at your fingertips.
        </li>
        <li>
          <span>Knowledge base for teaching: </span>A central repository of information supports curriculum for integrative residencies, fellowships, and other training programs. 
        </li>
        <li>
          <span>Collaboration for publication projects: </span>KNOW assembles the data so groups can help add to the literature reporting on natural agents in oncology care.
        </li>
        <li>
          <span>Growing competency and safety: </span>Articles in KNOW provide important information about safety tolerability, preparation, dosing, and side effects that is not readily available to clinicians. 
        </li>
      </ol>
    </div>
  </section>
);

export default Explanation;
