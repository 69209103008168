import React, { useEffect } from 'react';
import styles from './Header.css';
import TopNavButton from './topNavButton/TopNavButton';
import { useSelector, useDispatch } from 'react-redux';
import { getUser } from '../../data/selectors/userAuthSelectors';
import PageNavButton from './pageNavButton/PageNavButton';
import { logoutUser, verifyUser } from '../../data/actions/userAuthActions';
import { Link } from 'react-router-dom';

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(verifyUser());
  }, []);


  const handleLogout = () => {
    dispatch(logoutUser());
  };

  let pageNav;
  if(!user.email) {
    pageNav = [
      <PageNavButton key={'Home'} text={'KNOW Home'} link={'/'} />,
      <PageNavButton key={'Methodology'} text={'Methodology'} link={'/methodology'} />,
      <PageNavButton key={'Studies'} text={'Studies'} link={'/login'} />,
      <PageNavButton key={'Interactions'} text={'Interactions'} link={'/interactions'} />
    ];
  } else {
    pageNav = [
      <PageNavButton key={'Home'} text={'KNOW Home'} link={'/'} />,
      <PageNavButton key={'Methodology'} text={'Methodology'} link={'/methodology'} />,
      <PageNavButton key={'Studies'} text={'Studies'} link={'/studies'} />,
      <PageNavButton key={'Pearls'} text={'Clinical Pearls'} link={'/pearls'} />,
      <PageNavButton key={'Interactions'} text={'Interactions'} link={'/interactions'} />
    ];
    if(user.role === 'allied') pageNav.splice(3, 1);
  }

  let topNav;
  if(!user.email) {
    topNav = [
      <TopNavButton key='donate' text={'Donate'} link="/donate" />,
      <TopNavButton key='feedback' text={'Feedback'} link="/feedback" />,
      <TopNavButton key='join' text={'Join Now'} link="https://oncanp.org/join-now/" />,
      <TopNavButton key='login' text={'Log In'} link="/login" />
    ];
  } else {
    topNav = [
      <TopNavButton key='donate' text={'Donate'} link="/donate" />,
      <TopNavButton key='addPearl' text={'Add Pearl'} link="/add-pearl" />,
      <TopNavButton key='feedback' text={'Feedback'} link="/feedback" />,
      <TopNavButton key='logout' text={'Log Out'} link="/" handleLogout={handleLogout} />
    ];
    if(user.role === 'student' || user.role === 'allied') topNav.splice(1, 1);
    if(user.role === 'org-admin' || user.role === 'know-admin'){
      topNav.unshift(
        <TopNavButton key='orgAdmin' text='Org Admin' link='/org-admin' />
      );
    }
    if(user.role === 'know-admin'){
      topNav.unshift(
        <TopNavButton key='knowAdmin' text='Know Admin' link='/know-admin' />
      );
    }
  }

  return (
    
    <header>
      <div className={styles.nameTopNavContainer}>
        <Link to="/" className={styles.titleContainer}>
          <div className="title">
            <h1>KNOW</h1>
            <h4>Knowledge in Integrative Oncology Website</h4>
          </div>
        </Link>
        <nav className={styles.topNav}>
          {topNav}
        </nav>
      </div>

      <div id="logo"></div>

      { 
        pageNav.length === 5 ?
          (<nav className={`${styles.pageNav} ${styles.pageNavFull}`}>
            {pageNav}
          </nav>)
          : 
          (<nav className={styles.pageNav}>
            {pageNav}
          </nav>)
      }
    </header>
  );
};

export default Header;
