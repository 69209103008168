import React from 'react';
import MethodButtons from './methodButtons/MethodButtons';
import { useMethods } from '../../hooks/methods';
import styles from './Methodology.css';


const Methodology = () => {
  const { handleClick, component, method } = useMethods();

  return (
    <div className={`${styles.Methodology} grid-x grid-container`}>
      <div className="cell small-12 medium-3">
        <MethodButtons onClick={handleClick} method={method} />
      </div>
      <div className="cell small-12 medium-9">
        <section id='method-display'>
          <div>{component}</div>
        </section>
      </div>
    </div>
  );
};

export default Methodology;
