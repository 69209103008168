import { SET_USER_LOADING, SET_USER, SET_AUTH_ERROR, LOGOUT_USER } from '../actions/userAuthActions';


const initialState = {
  userLoading: true,
  user: {},
  error: ''
};

export const userAuthReducer = (state = initialState, action) => {
  switch(action.type) {
    case SET_USER_LOADING:
      return { ...state, userLoading: true };
    case SET_USER:
      return { ...state, userLoading: false, user: action.payload };
    case SET_AUTH_ERROR:
      return { ...state, userLoading: false, error: action.payload };
    case LOGOUT_USER:
      return { ...state, userLoading: false, user: {} };
    default:
      return state;
  }
};
