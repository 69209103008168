import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../data/actions/userAuthActions';
import { getUser, getAuthError, getUserLoading } from '../../data/selectors/userAuthSelectors';
import { Redirect, useHistory } from 'react-router-dom';
import styles from './login.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const user = useSelector(getUser);
  const authError = useSelector(getAuthError);
  const userLoading = useSelector(getUserLoading);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleEmailChange = ({ target }) => {
    setEmail(target.value);
  };

  const handlePasswordChange = ({ target }) => {
    setPassword(target.value);
  };

  const handleLoginSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    dispatch(setUser(email, password))
      .then(() => {
        setLoading(false);
      });
  };

  const handlePasswordReset = () => {
    return history.replace('/forgot-password');
  };

  if(loading) return <LoadingSpinner />;
  

  if(user.email && !userLoading) {
    return <Redirect to='/' />;
  }

  return (
    <div className={styles.login}>
      <div className={styles.info}>
        <p>
          <b>KNOW</b> is a dynamic clinical and educational tool that provides summaries of up-to-date- research in integrative oncology.
        </p>
        <p>
          <b>KNOW</b> is designed to help you quickly access pertinent information to make evidence-informed decisions.
        </p>
      </div>
      <form onSubmit={handleLoginSubmit}>
        <input type='text' onChange={handleEmailChange} value={email} placeholder='email address'/>
        <input type='password' onChange={handlePasswordChange} value={password} placeholder='password'/>
        <input type='submit' className={styles.imAButton} value='Login' />
      </form>
      <button className={styles.imAButton} onClick={handlePasswordReset}>Forgot your password?</button>
      {authError && <p>{authError.message}</p>}
    </div>
  );
};

export default Login;
