import { searchSummariesWithFilter, searchSupportLitWithFilter } from '../../../services/studies';

const memoizedSearch = {};
export default function executeSearch(searchQuery, setSummarizedCallback, setSupportCallback = null) {
  const searchQueryJson = JSON.stringify(searchQuery);
  if(memoizedSearch[searchQueryJson]) {
    setSummarizedCallback(JSON.parse(memoizedSearch[searchQueryJson].summarized));
    if(setSupportCallback) setSupportCallback(JSON.parse(memoizedSearch[searchQueryJson].supportLit));
    return Promise.resolve(JSON.parse(memoizedSearch[searchQueryJson].results));
  }
  memoizedSearch[searchQueryJson] = {};

  const promiseArray = [
    searchSummariesWithFilter(searchQuery)
  ];
  if(setSupportCallback) promiseArray.push(searchSupportLitWithFilter(searchQuery));
  
  return Promise.all(promiseArray)
    .then(results => {
      results.forEach(({ promiseId, res }) => {
        switch(promiseId) {
          case 'summarized':
            setSummarizedCallback(res);
            memoizedSearch[searchQueryJson].summarized = JSON.stringify(res);
            break;
          case 'support':
            setSupportCallback(res);
            memoizedSearch[searchQueryJson].supportLit = JSON.stringify(res);
            break;
        }
      });
      memoizedSearch[searchQueryJson].results = JSON.stringify(results);
      return results;
    })
    .catch(error => console.log(error)); // eslint-disable-line no-console
}
