// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._29ikew7itsvIYIEMXp0U_w {\r\n  display: flex;\r\n  justify-content: right;\r\n  align-items: center;\r\n}\r\n\r\n  ._29ikew7itsvIYIEMXp0U_w button {\r\n    margin: 1em;\r\n    padding: 5px;\r\n    width: auto;\r\n    background-color: #f6f6f6;\r\n    border: 1px solid #dddddd;\r\n    border-radius: 1em;\r\n    box-shadow: none;\r\n    font-weight: bold;\r\n    padding-top: .6em;\r\n    padding-bottom: .6em;\r\n  }\r\n\r\n  ._29ikew7itsvIYIEMXp0U_w span {\r\n    margin: 1em;\r\n    font-weight: bold;\r\n  }\r\n\r\n._1ONZ8smTD3d_lxUxbu6URk {\r\n  color: rgb(7, 192, 7);\r\n}\r\n\r\n.Yd6OOfB7CbElT_4UTZiAd {\r\n  color: red;\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  ._29ikew7itsvIYIEMXp0U_w {\r\n    flex-direction: column;\r\n  }\r\n}", "",{"version":3,"sources":["resultUtilityButtons.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AAmBrB;;EAjBE;IACE,WAAW;IACX,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,oBAAoB;EACtB;;EAEA;IACE,WAAW;IACX,iBAAiB;EACnB;;AAGF;EACE,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE;IACE,sBAAsB;EACxB;AACF","file":"resultUtilityButtons.css","sourcesContent":[".utilityButtonsContainer {\r\n  display: flex;\r\n  justify-content: right;\r\n  align-items: center;\r\n\r\n  button {\r\n    margin: 1em;\r\n    padding: 5px;\r\n    width: auto;\r\n    background-color: #f6f6f6;\r\n    border: 1px solid #dddddd;\r\n    border-radius: 1em;\r\n    box-shadow: none;\r\n    font-weight: bold;\r\n    padding-top: .6em;\r\n    padding-bottom: .6em;\r\n  }\r\n\r\n  span {\r\n    margin: 1em;\r\n    font-weight: bold;\r\n  }\r\n}\r\n\r\n.greenMessage {\r\n  color: rgb(7, 192, 7);\r\n}\r\n\r\n.redMessage {\r\n  color: red;\r\n}\r\n\r\n@media (max-width: 425px) {\r\n  .utilityButtonsContainer {\r\n    flex-direction: column;\r\n  }\r\n}"]}]);
// Exports
exports.locals = {
	"utilityButtonsContainer": "_29ikew7itsvIYIEMXp0U_w",
	"greenMessage": "_1ONZ8smTD3d_lxUxbu6URk",
	"redMessage": "Yd6OOfB7CbElT_4UTZiAd"
};
module.exports = exports;
