import React from 'react';

const Attributions = () => (
  <details>
    <summary>
      Attributions
    </summary>

    <div className="textHighlight">
      <span className="top" style={{ 'fontWeight': 'bold' }}>Website made possible by:</span>
      <ul>
        <li>Oncology Association of Naturopathic Physicians <span>(<a href='https://oncanp.org/' target='_blank' rel="noopener noreferrer">OncANP.org</a>)</span></li>
        <li>Ottawa Integrative Cancer Centre <span>(<a href='https://oicc.ca/en/' target='_blank' rel="noopener noreferrer">oicc.ca</a>)</span></li>
        <li>Scheidel Foundation <span>(<a href='http://scheidelfoundation.org/' target='_blank' rel="noopener noreferrer">scheidelfoundation.org</a>)</span></li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>Research Directors:</span>
      <ul>
        <li>Jen Green ND FABNO</li>
        <li>Heather Wright ND FABNO</li>
        <li>Casey Martell, ND, FABNO, MBA</li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>KNOW Senior Research Associates:</span>
      <ul>
        <li>Sarah Soles, ND</li>
        <li>Anne Thiel, ND</li>
        <li>Thanasi Psihogios, ND</li>
        <li>Hallie Armstrong, ND</li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>KNOW Research Associates:</span>
      <ul>
        <li>Sukriti Bhardwaj ND, 1st year resident, Integrative Cancer Center, CCNM</li>
        <li>Rick Bhim, ND, 2nd year resident, Integrative Cancer Center, CCNM</li>
        <li>Erica Eckstrand, ND Candidate, CCNM</li>
        <li>Julie Rhodes, ND Candidate, NUNM</li>
        <li>Kristina Schieferecke, ND Candidate, SCNM</li>
        <li>Jennifer Tang, ND Candidate, CCNM)</li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>KNOW Information Specialists &amp; Medical Librarians:</span>
      <ul>
        <li>Emily Gallaher MLS</li>
        <li>Alexia Rodarmer</li>
        <li>Becky Skidmore BA(H), MLS</li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>Special thanks to:</span>
      <ul>
        <li>Institute for Natural Medicine</li>
        <li>OncANP Research Committee</li>
        <li>Ellen Conte, ND</li>
        <li>Alanna Welsh, ND</li>
        <li>The Natural Medicine Journal</li>
        <li>OncANP Board of Directors</li>
        <li>Canadian College of Naturopathic Medicine</li>
        <li>University of Toronto</li>
        <li>Corey Murphy, OncANP Executive Director</li>
        <li>Beyond Conventional Cancer Therapies</li>
      </ul>

      <span style={{ 'fontWeight': 'bold' }}>KNOW Advisory Board Members:</span>
      <ul>
        <li><strong>Dugald Seely</strong>&nbsp;ND, MSc, FABNO<br />Founder,&nbsp;<a className="ui-link" href="http://www.oicc.ca/en/about/our-team/clinic-staff/naturopathic-medicine" target="_blank" rel="noopener noreferrer">Ottawa Integrative Cancer Centre</a></li>
        <li><strong>Lynda Balneaves</strong>, PhD, RN<br />President,&nbsp;<a className="ui-link" href="https://integrativeonc.org/" target="_blank" rel="noopener noreferrer">Society of Integrative Oncology</a><br />Associate Professor, College of Nursing, Rady Faculty of Health Sciences, University of Manitoba</li>
        <li><strong>Eric Secor</strong>&nbsp;ND, Associate Medical Director, Integrative Medicine; Hartford Healthcare Cancer Institute, Hartford Hospital</li>
        <li><strong>Ryan Bradley</strong>, ND, Assistant Director<br /><a className="ui-link" href="https://helfgott.nunm.edu/investigators-staff/ryan-bradley-nd-mph/" target="_blank" rel="noopener noreferrer">Helfgott Research Institute</a></li>
        <li><strong>Nick Degroot</strong>, ND, Dean<br /><a className="ui-link" href="https://www.ccnm.edu/about-ccnm/academic-leadership" target="_blank" rel="noopener noreferrer">Canadian College of Naturopathic Medicine</a></li>
        <li><strong>Laura Pole</strong>, RN, MSc - Chef<br /><a className="ui-link" href="http://smithcenter.org/about-smith-center/board_staff/" target="_blank" rel="noopener noreferrer">Oncology Clinical Nurse Specialist</a>, Educator, Researcher, &nbsp;<a className="ui-link" href="https://www.commonweal.org/" target="_blank" rel="noopener noreferrer">Commonweal</a></li>
        <li><strong>Peter Pappadogianis</strong>&nbsp;BSc, MSc, ND, Director<br /><a className="ui-link" href="http://pattersoninstitute.ca/" target="_blank" rel="noopener noreferrer">Patterson Institute for Integrative Cancer</a></li>
        <li><strong>Darren Orloff</strong>, Senior Manager of Software Engineering, CISCO</li>
        <li><strong>Karen Bolak</strong>, Associate Professor, Oakland University, Rochester MI</li>
      </ul>
    </div>
  </details>
);

export default Attributions;
