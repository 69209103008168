// https://github.com/zloirock/core-js#babelpolyfill
//*** "As a full equal of @babel/polyfill, you can use this:"
// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
//***

// polyfill Promise.all
import 'core-js/features/promise';

import React from 'react';
import { render } from 'react-dom';
import App from './components/App';
import './index.css';
import { Provider } from 'react-redux';
import store from './store';

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
