import React from 'react';
import styles from './Video.css';

const Video = () => (
  <section id='video'>
    <h4>KNOW Database 101</h4>
    <div className={styles.videoWrapper}>
      <iframe src="https://drive.google.com/file/d/1K4D9UWQBDqXdel3JYazf3KKADqhZmHRH/preview" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    </div>
  </section>
);

export default Video;
