import React, { useState } from 'react';
import PropTypes from 'prop-types';
//import { patchOrg } from '../../services/knowAdmin;
import styles from './EditOrg.css';
import formatDate from '../../utils/formatDate';
import { patchOrg } from '../../services/knowAdmin';

const propTypes = {
  org: PropTypes.shape({
    _id: PropTypes.number.isRequired,
    org_name: PropTypes.string.isRequired,
    contact_person: PropTypes.string.isRequired,
    contact_phone_number: PropTypes.string.isRequired,
    contact_email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    web_address: PropTypes.string,
    joined: PropTypes.string.isRequired,
    license_expire: PropTypes.string.isRequired,
    max_users: PropTypes.number.isRequired
  }),
  closeCallback: PropTypes.func.isRequired
};

export default function EditOrg({ org, closeCallback }) {
  if(!org) return null;
  const defaultDate = new Date(org.license_expire);
  const formattedDefaultDate = defaultDate.toISOString().substr(0, 10);

  const [contactPerson, setContactPerson] = useState(org.contact_person);
  const [contactPhoneNumber, setContactPhoneNumber] = useState(org.contact_phone_number);
  const [contactEmail, setContactEmail] = useState(org.contact_email);
  const [address, setAddress] = useState(org.address);
  const [webAddress, setWebAddress] = useState(org.web_address);
  const [licenseExpiration, setLicenseExpiration] = useState(formattedDefaultDate);
  const [maxUsers, setMaxUsers] = useState(org.max_users);

  const handleContactPersonChange = ({ target }) => setContactPerson(target.value);
  const handleContactPhoneNumberChange = ({ target }) => setContactPhoneNumber(target.value);
  const handleContactEmailChange = ({ target }) => setContactEmail(target.value);
  const handleAddressChange = ({ target }) => setAddress(target.value);
  const handleWebAddressChange = ({ target }) => setWebAddress(target.value);
  const handleLicenseExpirationChange = ({ target }) => setLicenseExpiration(target.value);
  const handleMaxUsersChange = ({ target }) => setMaxUsers(target.value);

  const handleCancel = () => closeCallback(null);
  const handleSubmit = () => {
    patchOrg({ contact_person: contactPerson,
      contact_phone_number: contactPhoneNumber,
      contact_email: contactEmail,
      address,
      web_address: webAddress,
      license_expire: licenseExpiration,
      max_users: maxUsers
    }, org._id)
      .then(editedOrg => closeCallback(editedOrg))
      .catch(err => console.log(err));
  };

  return (
    <div className={styles.backgroundDiv}>
      <div className={styles.editModal}>
        <p>Edit Organization</p>
        <table>
          <tbody>
            <tr>
              <td>Organization Name</td>
              <td>{org.org_name}</td>
            </tr>
            <tr>
              <td>Contact Person</td>
              <td><input type='text' value={contactPerson} onChange={handleContactPersonChange}/></td>
            </tr>
            <tr>
              <td>Contact Phone Number</td>
              <td><input type='text' value={contactPhoneNumber} onChange={handleContactPhoneNumberChange} /></td>
            </tr>
            <tr>
              <td>Contact Email</td>
              <td><input type='text' value={contactEmail} onChange={handleContactEmailChange} /></td>
            </tr>
            <tr>
              <td>Address</td>
              <td><input type='text' value={address} onChange={handleAddressChange} /></td>
            </tr>
            <tr>
              <td>Web Address</td>
              <td><input type='text' value={webAddress} onChange={handleWebAddressChange} /></td>
            </tr>
            <tr>
              <td>Joined</td>
              <td>{formatDate(org.joined)}</td>
            </tr>
            <tr>
              <td>License Expiration</td>
              <td>
                <input type='date' value={licenseExpiration} onChange={handleLicenseExpirationChange} />
              </td>
            </tr>
            <tr>
              <td>Max Users</td>
              <td><input type='text' value={maxUsers} onChange={handleMaxUsersChange} /></td>
            </tr>
          </tbody>
        </table>
        <button type='button' onClick={handleCancel}>Cancel Edits</button>
        <button type='button' onClick={handleSubmit}>Save Edits</button>
      </div>
    </div>
  );
}

EditOrg.propTypes = propTypes;
