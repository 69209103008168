import React from 'react';

const Breast = () => (
  <>
    <h1>Breast Methodology</h1>
    <br/>
    <h2>BREAST RESEARCH QUESTION:</h2>
    <p>What is the role of integrative therapies in the clinical management of breast cancer and survivorship?</p>
    <br />
    <h2>INCLUSION CRITERIA:</h2>
    <ul>
      <li>English language article</li>
      <li>Human</li>
      <li>All tumor types/neoplastic cases [minimum of 25% study population with cancer]</li>
      <li>KNOW beta topic -- contains data for all years</li>
      <li>All outcomes including: Overall Survival (OS), Progression Free Survival (PFS), Quality of Life (QoL), and side effect management.</li>
      <li>Females and males</li>
      <li>All nutraceuticals (including oral supplements, dietary, intravenous, injectable, topical)</li>
      <li>Diet therapy (Mediterranean, ornish, ketogenic, vegetarian, etc.)</li>
      <li>Secondary prevention (articles that report data to prevent cancer from recurring)</li>
      <li>Systematic Review articles and meta analyses with human data</li>
      <li>Clinical guidelines</li>
    </ul>
    <br />
    <h2>EXCLUSION CRITERIA:</h2>
    <ul>
      <li>Non- English language article</li>
      <li>Non- human (eg. in vitro or in vivo non-human animal)</li>
      <li>Non-neoplasms</li>
      <li>Non-nutraceuticals/Non-diet CAM therapies (yoga, acupuncture, massage, etc.)</li>
      <li>Primary prevention (articles that report data to prevent cancer from occurring)</li>
      <li>Drug only trials (no metformin, LDN, DCA, IPT, light therapy, metronomic chemo)</li>
      <li>Mind Body supportlit</li>
      <li>Exercise only supportlit (no supplement, dietary, intravenous or topical therapies)</li>
      <li>Prevalence/usage of CAM</li>
      <li>Conference proceedings, trial postings without results; interim analyses</li>
    </ul>
    <br />
    <h2>KNOW SEARCH METHODOLOGY – INTEGRATIVE TREATMENTS IN BREAST CANCER:</h2>
    <p>
        Using the dietary supplement subset available in both PubMed and MEDLINE as our base, we created a line-by-line version of this filter for use in Embase. With the help of naturopathic doctors on the team, we identified additional relevant vocabulary that we incorporated into the filter. This filter was then paired with the pertinent vocabulary for breast cancer.
    </p>
    <br />
    <p>
        We ran searches in Ovid MEDLINE, Ovid MEDLINE In-Process & Other Non-Indexed Citations and Embase Classic+Embase on May 31, 2015. Strategies utilized a combination of controlled vocabulary (e.g., Breast Cancer, Diet Supplementation, Omega 3 Fatty Acid) and keywords (e.g., breast cancer, nutraceutical, fish oil). Vocabulary and syntax were adjusted across the databases. No language or date restrictions were applied but animal-only and opinion-pieces were removed from the results.
    </p>
    <br/>
    <p>The search will be updated on a scheduled basis and relevant citations appraised for inclusion in KNOW.</p>
  </>
);

export default Breast;
