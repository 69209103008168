import React from 'react';
import ExternalLinks from './ExternalLinks.jsx';
import InternalLinks from './InternalLinks.jsx';
import Attributions from './Attributions.jsx';
import './Sidebar.css';

const Sidebar = () => (
  <aside className="sideBar">
    <p id='dedication'><span style={{ 'fontWeight': 'bold' }}>KNOW</span> is dedicated to all people who have experienced cancer, and with gratitude for those who volunteered in research to make this knowledge available to us.</p>
    <Attributions />
    <div className="links">
      <InternalLinks />
    </div>
    <ExternalLinks />
  </aside>
);

export default Sidebar;
