import React from 'react';
import { Link } from 'react-router-dom';
const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef

const InternalLinks = () => (
  <>
    <Link to='/studies' className="textLink">Most Recent Studies</Link>
    
    <a id='faqs' href={`${ASSETS_PATH}/FAQs and Definitions.pdf`} target='_blank' rel='noopener noreferrer' className="textLink">FAQs and Definitions</a>
  </>
);

export default InternalLinks;
