// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._1xgxBbtb1dufOwJnmSJQOF {\r\n  align-self: center;\r\n  flex: 1 1 20%;\r\n  margin-left: .5em;\r\n  margin-right: .5em;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  padding: 1rem;\r\n}\r\n\r\n.VwQfXjw6oK1A-n9CGUFj7 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: left;\r\n  top: 40%;\r\n  left: 50%;\r\n  position: fixed;\r\n  overflow: auto;\r\n  z-index: 2;\r\n  margin: 5% auto;\r\n  padding: 1rem;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  min-width: 7em;\r\n  width: auto;\r\n  height: 90%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n._1lUh_utsv1of8vA1ijIlUB {\r\n  display: none;\r\n}", "",{"version":3,"sources":["searchInfo.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,QAAQ;EACR,SAAS;EACT,eAAe;EACf,cAAc;EACd,UAAU;EACV,eAAe;EACf,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,cAAc;EACd,WAAW;EACX,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,aAAa;AACf","file":"searchInfo.css","sourcesContent":[".modal {\r\n  align-self: center;\r\n  flex: 1 1 20%;\r\n  margin-left: .5em;\r\n  margin-right: .5em;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  padding: 1rem;\r\n}\r\n\r\n.showModal {\r\n  display: flex;\r\n  flex-direction: column;\r\n  text-align: left;\r\n  top: 40%;\r\n  left: 50%;\r\n  position: fixed;\r\n  overflow: auto;\r\n  z-index: 2;\r\n  margin: 5% auto;\r\n  padding: 1rem;\r\n  border: solid #dddddd 1px;\r\n  border-radius: 5px;\r\n  background-color: #f6f6f6;\r\n  min-width: 7em;\r\n  width: auto;\r\n  height: 90%;\r\n  transform: translate(-50%, -50%);\r\n}\r\n\r\n.hideModal {\r\n  display: none;\r\n}"]}]);
// Exports
exports.locals = {
	"modal": "_1xgxBbtb1dufOwJnmSJQOF",
	"showModal": "VwQfXjw6oK1A-n9CGUFj7",
	"hideModal": "_1lUh_utsv1of8vA1ijIlUB"
};
module.exports = exports;
