import React from 'react';
import './Welcome.css';

const Welcome = () => (
  <section id='welcome'>
    <h3>WELCOME to KNOW</h3>
    <p><span style={{ 'fontWeight': 'bold' }}>KNOW</span> is a dynamic clinical, research, and educational tool that allows you to quickly access up-to-date research on natural agents in cancer care. It systematically summarizes human studies from Medline and EMBASE and makes the data searchable by Tumor Type, Natural Therapy, Conventional Treatment, and Side Effect. You can copy references or full summaries into referral letters, patient recommendations, handouts, presentations, and websites.</p>
  </section>
);

export default Welcome;
