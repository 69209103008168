import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { Redirect } from 'react-router-dom';
import { submitPearl } from '../../services/pearls';
import { addPearlContainer, title, imAButton, keywordContainer } from './addPearl.css';
import { naturalTherapyAgents, conventionalTreatmentAgents, sideEffects, tumorType } from '../../utils/dropdownSeedData';
import ModalGroup from '../modalGroup/ModalGroup';


export default function AddPearl() {
  const user = useSelector(getUser);
  const userLoading = useSelector(getUserLoading);
  const [pearlTitle, setPearlTitle] = useState('');
  const [pearlBody, setPearlBody] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [keywords, setKeywords] = useState({});
  const [freeKeywords, setFreeKeywords] = useState('');

  useEffect(() => {
    if(pearlBody && pearlTitle) setSubmitDisabled(false);
    else setSubmitDisabled(true);
  }, [pearlTitle, pearlBody]);

  if(userLoading || submitLoading) return <LoadingSpinner />;
  if(!user.email) return <Redirect to='/login' />;

  const handleKeywordChange = keywordOptionsObj => setKeywords(keywordOptionsObj);
  const handleFreeKeyword = ({ target }) => setFreeKeywords(target.value);
  const handleTitle = ({ target }) => setPearlTitle(target.value);
  const handleBody = ({ target }) => setPearlBody(target.value);

  const handleSubmit = event => {
    event.preventDefault();
    setSubmitLoading(true);
    const pearl = {
      title: pearlTitle,
      body: pearlBody,
      author: user.display_name,
      organizations: user.organizations,
      // eslint-disable-next-line quotes
      keywords: { ...keywords, "Free Keywords": freeKeywords.split(', ') }
    };
    submitPearl(pearl)
      .then(() => {
        setMessage('Your Clinical Pearl was successfully submitted!');
        setSubmitLoading(false);
      })
      .catch(err => {
        setMessage(err.message);
        setSubmitLoading(false);
      });
  };

  const keywordArrays = [naturalTherapyAgents, conventionalTreatmentAgents, sideEffects, tumorType];

  return (
    <div className={addPearlContainer}>
      {message}
      <h1>Submit a Clinical Pearl!</h1>
      <form onSubmit={handleSubmit}>
        <input type='text' className={title} value={pearlTitle} onChange={handleTitle} placeholder='Title' />
        <textarea value={pearlBody} onChange={handleBody} placeholder='Share a pearl!' />
        <section>
          <h2>Keywords</h2>
          <div className={keywordContainer}>
            <p>
              For best results, please check keywords from the lists below first and then type any other keywords in the Free text Keywords text box below.
            </p>
            <ModalGroup modalGroupArray={keywordArrays} setCallback={handleKeywordChange} includeAllOption={false} />
            <label>
              Free Keywords
              <input type='text' value={freeKeywords} onChange={handleFreeKeyword} placeholder='Add comma separated keywords here that do not appear in the checkbox lists above.' />
            </label>
          </div>
        </section>
        <input type='submit' className={imAButton} disabled={submitDisabled} value='Submit Pearl' />
      </form>
      <section>
        <p>
          By clicking Submit Pearl, you are agreeing to the following:
        </p>
        <br />
        <ol>
          <li>
            Content I am posting is relevant to the above keywords and will contribute to the practice of naturopathic oncology
          </li>
          <li>
            Content I am posting is based on my personal clinical experience
          </li>
          <li>
            Content I am posting is free of commercial bias and does not mention specific brands. Note that describing detailed dosing strategies IS encouraged and you are welcome to include combination products described as a list of ingredients.
          </li>
          <li>
            I understand that this content will be displayed with my name.
          </li>
          <li>
            For a general observations: Please list number of years practicing at time of submission, number of years using the therapy, and the observation.
          </li>
          <li>
            For a single case, please provide the age, diagnosis, intervention (dose, frequency, duration) and response (negative or positive – both useful!). Please include any contraindications to treatment or observed side effects.
          </li>
          <li>
            For case series, please specify how many patients, their diagnosis types, intervention (dose, frequency, duration) and response (positive and negative). Please include any contraindications to treatment or observed side effects.
          </li>
        </ol>
      </section>
    </div>
  );
}
