import React, { useState } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  imgsAndLinks: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  })).isRequired
};

// Bainer = Banner. Names like banner and ad were avoided to circumvent adblocking, which appears to work via keywords.
export default function Bainer({ imgsAndLinks }) {
  if(!imgsAndLinks.length) return null;
  const [imgIndex, setImgIndex] = useState(0);
  
  setTimeout(() => {
    const nextBainer = imgIndex === imgsAndLinks.length - 1 ? 0 : imgIndex + 1;
    setImgIndex(nextBainer);
  }, 5000);

  return (
    <a style={{ display: 'block' }} href={imgsAndLinks[imgIndex].href} target='_blank' rel='noopener noreferrer'>
      <img src={imgsAndLinks[imgIndex].src} 
        alt={imgsAndLinks[imgIndex].alt} />
    </a>
  );
}

Bainer.propTypes = propTypes;
