import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import defaultStyles from './modalGroup.css';
import { TiFilter } from 'react-icons/ti';

const propTypes = {
  optionsArray: PropTypes.arrayOf(
    PropTypes.string.isRequired,
  ).isRequired,
  openModalGroupBool: PropTypes.bool,
  modalFunctions: PropTypes.shape({
    openCallback: PropTypes.func.isRequired,
    closeCallback: PropTypes.func.isRequired
  }),
  setCallback: PropTypes.func.isRequired,
  includeAllOption: PropTypes.bool.isRequired,
  styles: PropTypes.object
};

//optionsArray looks like [Label, All options, option1, option2, etc]
//["Tumor Type", "All Tumor Types", "Bladder", etc]

export default function Modal({ optionsArray, openModalGroupBool = false, modalFunctions = {}, setCallback, includeAllOption, styles = defaultStyles }) {
  const { showModal, hideModal, modalLabel, modal } = styles;
  const [modalLabelText, allOption] = optionsArray;
  const individualDisplayOptions = optionsArray.slice(2);
  const initializeState = () => {
    const initialState = individualDisplayOptions.reduce((acc, filterOptions) => {
      return { ...acc, [filterOptions]: false };
    }, {});
    if(includeAllOption) initialState[allOption] = true;
    return initialState;
  };
  const [modalState, setModalState] = useState(false);
  const [optionFilterText, setOptionFilterText] = useState('');
  const [optionSnapshot, setOptionSnapshot] = useState([]);
  const [optionsState, setOptionsState] = useState(initializeState);

  //store state prior to opening modal so it can be reverted if user clicks outside of it
  const revertOptions = useRef(optionsState);

  //keep track of true options
  const trueOptions = Object.entries(optionsState)
    .filter(entry => entry[1])
    .map(trueEntry => trueEntry[0]);

  //To open a modal, make sure no other modal is open, then set state to trigger a change in style. Let modal container know that a snapshot is open so that no others can open. Set a snapshot to keep track of any changes when the modal is finally closed.
  const openModal = () => {
    if(modalState) return;
    if(!openModalGroupBool) {
      setModalState(true);
      setOptionSnapshot(trueOptions);
      revertOptions.current = optionsState;
      if(modalFunctions) modalFunctions.openCallback();
    }
  };

  //On modal close, pass trueOptions to the parent. Trigger a style change to close the modal, and let the parent know the modal has closed. If there was a change in selected options, execute a new search if a searchCallback was provided
  const closeModal = (event = null) => {
    if(event) { // there will only be an event if the user clicked the 'Apply' button
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();
      revertOptions.current = optionsState;
      if(optionSnapshot.toString() !== trueOptions.toString()) setCallback({ [modalLabelText]: trueOptions });
    }
    else {
      const revertState = { ...revertOptions.current };
      setOptionsState(revertState);
    }
    setOptionFilterText('');
    setModalState(false);
    if(modalFunctions) modalFunctions.closeCallback();
  };

  const modalRef = useRef(null);
  const clickEventListener = ({ target }) => {
    const { current: clickedElement } = modalRef;
    if(clickedElement && (target !== clickedElement && !clickedElement.contains(target))) {
      closeModal();
    }
  };

  useEffect(() => {
    if(modalState)
      document.body.addEventListener('click', clickEventListener);
    else
      document.body.removeEventListener('click', clickEventListener);
    return () => document.body.removeEventListener('click', clickEventListener);
  }, [modalState]);

  const handleOptionFilterText = ({ target }) => setOptionFilterText(target.value);

  //handle toggling of a checkbox (except the first, which uses a different callback). If the All option is in use, enforce rules for checkbox selection.
  const handleOptionClick = ({ target }) => {
    const newState = {
      ...optionsState,
      [target.value]: !optionsState[target.value]
    };
    if(includeAllOption && newState[allOption]) newState[allOption] = false;
    if(includeAllOption && !Object.values(newState).includes(true)) newState[allOption] = true;
    setOptionsState(newState);
  };

  //Prepare checkboxes and labels for rendering (except All option, which will be handled separately). Filter rendered options based on optionFilterText.
  const generateOptionHTML = (option, onClickCallback) => (
    <label key={option}>
      <input type='checkbox' checked={optionsState[option]} value={option} onChange={onClickCallback}  />{option}
    </label>
  );

  const filteredArray = optionFilterText ?
    individualDisplayOptions.filter(option => {
      if(trueOptions.includes(option)) return true;
      if(optionSnapshot.includes(option)) return true;
      const lowerCaseOption = option.toLowerCase();
      const lowerCaseFilterText = optionFilterText.toLowerCase();
      if(lowerCaseOption.includes(lowerCaseFilterText)) return true;
      return false;
    }) : 
    individualDisplayOptions;

  const optionCheckboxes = filteredArray.map(option => generateOptionHTML(option, handleOptionClick));

  let firstOption = null;
  if(includeAllOption && allOption.toLowerCase().includes(optionFilterText.toLocaleLowerCase()))
    firstOption = generateOptionHTML(allOption, () => setOptionsState(initializeState));

  const openCloseModal = modalState ? showModal : hideModal;
  const dropdownIcon = <TiFilter />;

  return (
    <>
      <span className={modalLabel}>{modalLabel}</span>
      <span>
        <div className={modal} onClick={openModal}>
          {trueOptions.join(', ') || 'None'} {dropdownIcon}
          <div ref={modalRef} className={openCloseModal}>
            <button onClick={closeModal}>Apply<TiFilter /></button>
            <input type='text' value={optionFilterText} onChange={handleOptionFilterText} />
            {firstOption}
            {optionCheckboxes}
          </div>
        </div>
      </span>
    </>
  );
}

Modal.propTypes = propTypes;
