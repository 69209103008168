const { ASSETS_PATH } = process.env; // eslint-disable-line no-undef
import React from 'react';
import styles from './Footer.css';
import { Link } from 'react-router-dom';
import Bainer from '../bainer/Bainer';
import { getUser } from '../../data/selectors/userAuthSelectors';
import { useSelector } from 'react-redux';

const Footer = () => {
  const user = useSelector(getUser);
  const showBainer = user.organizations === 1;

  const imgsAndLinks = [
    {
      src: `${ASSETS_PATH}/bainers/b1.jpg`,
      href: 'https://www.gomegaspore.com',
      alt: 'MegaSporeBiotic'
    },
    {
      src: `${ASSETS_PATH}/bainers/b2.jpg`,
      href: 'https://www.listenandcare.com',
      alt: 'Naturopathic Specialists, LLC'
    },
    {
      src: `${ASSETS_PATH}/bainers/bioc.jpg`,
      href: 'https://www.biocept.com',
      alt: 'Biocept'
    },
    {
      src: `${ASSETS_PATH}/bainers/sio.jpg`,
      href: 'https://www.seattlend.com',
      alt: 'Seattle Integrative Oncology'
    }
  ];

  return (
    <footer className={styles.Footer}>
      {showBainer && <Bainer imgsAndLinks={imgsAndLinks} />}
      Phone &amp; Fax: 1-800-908-5175
      <br />
      Email <a href="mailto:info@knowoncology.org" className="ignore ui-link">info@knowoncology.org</a>
      <br />
      <br />
      The content on the KNOW website is not intended nor recommended as a substitute for medical advice, diagnosis, or treatment.<br />
      Always seek the advice of your own physician or other qualified health care professional regarding any medical questions or conditions.<br />
      The use of this website is governed by the <Link to='/terms-of-use'>Terms of Use</Link>.
      <br />
      <br />
      &copy; 2020 OncANP.org
    </footer>
  );
};

export default Footer;
