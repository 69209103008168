import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getUserLoading } from '../../data/selectors/userAuthSelectors';
import { Redirect } from 'react-router-dom';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import { getAdminOrg, inviteUsers, getOrgInvites, deleteUserInvitation, resendInvitation } from '../../services/orgAdmin';
import parseInvites from './funcs/parseInvites';
import formatDate from '../../utils/formatDate';
import OrgUserTable from '../orgUserTable/OrgUserTable';
import styles from './OrgAdmin.css';

export default function OrgAdmin() {
  const initialRender = useRef(true);
  const admin = useSelector(getUser);
  const adminLoading = useSelector(getUserLoading);
  const [orgLoading, setOrgLoading] = useState(false);
  const [invitesLoading, setInvitesLoading] = useState(false);
  const [orgUsersLoading, setOrgUsersLoading] = useState(false);
  const [organization, setOrganization] = useState({});
  const [openInvitations, setOpenInvitations] = useState('');
  const [inviteText, setInviteText] = useState('');
  const [message, setMessage] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [newUsersRole, setNewUsersRole] = useState('regular');

  useEffect(() => {
    setOrgLoading(true);
    if(!initialRender.current) {
      getAdminOrg(admin.organizations)
        .then(res => setOrganization(res));
      getOrgInvites(admin.organizations)
        .then(res => setOpenInvitations(res));
    } else initialRender.current = false;
  }, [admin]);

  useEffect(() => {
    if(message === '') return;
    const messageTimeout = setTimeout(() => setMessage(''), 20000);
    return () => clearTimeout(messageTimeout);
  }, [message]);

  useEffect(() => {
    if(organization._id && Array.isArray(openInvitations)) {
      setOrgLoading(false);
    }
  }, [organization, openInvitations]);

  if(
    orgUsersLoading ||
    orgLoading || 
    adminLoading ||
    invitesLoading
  ) return <LoadingSpinner />;
  if(admin.role !== 'org-admin' && admin.role !== 'know-admin') return <Redirect to='/' />;

  initialRender.current = false;

  const invitedUsers = openInvitations ? openInvitations.map((user, i) => (
    <tr key={user.email}>
      <td>{user.email}</td>
      <td>{formatDate(user.created)}</td>
      <td>
        <button type='button' onClick={() => {
          resendInvitation(user.email, admin.organizations)
            .then(() => {
              setMessage('Resent invitation.');
            })
            .catch(() => setMessage('There was a problem trying to resend the invitation. Please try again later.'));
        }}>
          Resend
        </button>
      </td>
      <td>
        <button type='button' onClick={() => {
          const confirmation = window.confirm('Are you sure you would like to rescind this invitation? A user will not be able to sign up with their previously sent invitation.');
          if(!confirmation){
            setMessage('Delete cancelled');
            return;
          } else {
            deleteUserInvitation(user.email, admin.organizations)
              .then(() => {
                const newInvitesArray = [...openInvitations];
                newInvitesArray.splice(i, 1);
                setMessage('Invitation rescinded.');
                setOpenInvitations(newInvitesArray);
              });
          }
        }}>
          Rescind
        </button>
      </td>
    </tr>
  )) : null;

  const handleRoleChange = ({ target }) => setNewUsersRole(target.value);
  const handleInvitesChange = ({ target }) => setInviteText(target.value);
  const handleInvitesSubmit = () => {
    try {
      setInvitesLoading(true);
      const formattedSignup = parseInvites(inviteText);
      inviteUsers(formattedSignup, admin.organizations, organization.license_expire, newUsersRole)
        .then(() => {
          getOrgInvites(admin.organizations)
            .then(res => {
              setOpenInvitations(res);
              setMessage('Emails for new users to signup has been sent!');
            });
        })
        .catch(error => {
          getOrgInvites(admin.organizations)
            .then(res => setOpenInvitations(res));
          setMessage(`Error trying to sign up new users: ${error.problematicSignups.map(problem => problem.reason).join(', ')}`);
        })
        .finally(() => setInvitesLoading(false));
      
    } catch(error) {
      setInvitesLoading(false);
      setMessage(`Error trying to sign up new users: ${error.message}`);
    }
  };

  const usersCountCallback = count => setUserCount(count);
  const usersLoadingCallback = loadingBool => setOrgUsersLoading(loadingBool);
  

  return (
    <main className={styles.orgAdminContainer}>
      <h1>Organization Administration</h1>
      <p className={styles.message}>{message}</p>
      <section className={styles.orgInfo}>
        <p>Organization Name: {organization.org_name}</p>
        <p>{userCount} out of {organization.max_users} users</p>
        <p>License Ends: {formatDate(organization.license_expire)}</p>
      </section>
      <div className={styles.functionSection}>
        <OrgUserTable 
          orgId={admin.organizations}
          countCallback={usersCountCallback}
          admin={admin}
          messageCallback={setMessage}
          loadingCallback={usersLoadingCallback}
          orgName={organization.org_name} />
      </div>
      <div className={styles.functionSection}>
        <details open={true}>
          <summary>New User Management</summary>
          <section>
            <p>Invite new users to your organization here. An invitation will be sent to seach email address in this field. Each email needs to be on a new line. If you would like the user&apos;s access to expire at a date other than the license period of your organization, you can optionally supply a date on the same line as the email address. The date needs to be separated by either a space, comma, or tab.</p>
            {admin.organizations === 1 && 
            <div className={styles.radioGroup}>
              <p>Select role for new users:</p>
              <label>
                <input type='radio' name='role' value='regular' defaultChecked={true} onChange={handleRoleChange} />
                Regular
              </label>
              <label>
                <input type='radio' name='role' value='allied' onChange={handleRoleChange} />
                Allied
              </label>
              <label>
                <input type='radio' name='role' value='student' onChange={handleRoleChange} />
                Student
              </label>
              <label>
                <input type='radio' name='role' value='org-admin' onChange={handleRoleChange} />
                Org Admin
              </label>
            </div>}
            <label htmlFor='userInvite'>Invite Users to Organization</label>
            <textarea id='userInvite' value={inviteText} onChange={handleInvitesChange} placeholder='example@123.com, 12/20/202l; another@456.com; example3@xyz.com, 7/20/2025'></textarea>
            <button type='button' onClick={handleInvitesSubmit}>Invite New Users</button>
          </section>
          <section>
            <p>Open Organization Invitations</p>
            <table>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Invitation Date</th>
                  <th>Resend Invitation</th>
                  <th>Rescind Invitation</th>
                </tr>
              </thead>
              <tbody>
                {invitedUsers}
              </tbody>
            </table>
          </section>
        </details>
      </div>
      <p className={styles.message}>{message}</p>
    </main>
  );
}
