export const TOGGLE_CHECK = 'TOGGLE_CHECK';
export const toggleCheck = (title, index) => ({
  type: TOGGLE_CHECK,
  payload: { title, index }
});

export const RESET = 'RESET';
export const reset = title => ({
  type: RESET,
  payload: { title }
});

export const SET_CHECKBOX_STATE = 'SET_CHECKBOX_STATE';
export const setCheckboxState = (title, checkboxArray) => ({
  type: SET_CHECKBOX_STATE,
  payload: {
    [title]: checkboxArray
  }
});

export const REINITIALIZE_STATE = 'REINITIALIZE_STATE';
export const reinitializeState = () => ({
  type: REINITIALIZE_STATE
});
