import React from 'react';
import { resultItem } from './pearls.css';
import PropTypes from 'prop-types';

const propTypes = {
  pearl: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    organizations: PropTypes.arrayOf(PropTypes.string),
    author: PropTypes.string,
    created: PropTypes.string,
    keywords: PropTypes.object
  })
}.isRequired;  

export default function Pearl({ pearl }) {
  const { _id, title, body, author, created } = pearl;
  const dateCreated = new Date(created).toDateString(); 

  return (
    <details key={_id} className={resultItem} open={true}>
      <summary>{title || 'Pearl'}</summary>
      <table>
        <tbody>
          <tr>
            <td>Pearl</td>
            <td>{body}</td>
          </tr>
          <tr>
            <td>Author</td>
            <td>{author}</td>
          </tr>
          <tr>
            <td>Created</td>
            <td>{dateCreated}</td>
          </tr>
        </tbody>
      </table>
    </details>  
  );
}
Pearl.propTypes = propTypes;
