/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUserLoading, getUser } from '../../data/selectors/userAuthSelectors';
import { Redirect } from 'react-router-dom';
import ResultsSection from './results/ResultsSection';
import styles from './Studies.css';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import createQuery, { emptyQuery } from './studiesFuncs/createQuery';
import ModalGroup from '../modalGroup/ModalGroup';
import { yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects } from '../../utils/dropdownSeedData';
import SynonymModal from '../modalGroup/SynonymModal';
import { getSynonymDictionaries } from '../../services/synonyms';
import SearchInfo from '../searchInfo/SearchInfo';
import executeSearch from './studiesFuncs/executeSearch';
import { TiFilter } from 'react-icons/ti';

const filterGroupArrays = [yearPublished, tumorType, naturalTherapyTypes, conventionalTreatmentTypes, sideEffects];
let naturalTherapyAgents = ['Natural Therapy Agents', 'All Natural Therapy Agents'];
let conventionalTreatmentAgents = ['Conventional Treatment Agents', 'All Conventional Treatment Agents'];

const initializeFilterState = () => {
  return filterGroupArrays.reduce((initialState, filterArray) => {
    const [filterName, allOption] = filterArray;
    return { ...initialState, [filterName]: [allOption] };
  }, {});
};

const Studies = () => {
  const userLoading = useSelector(getUserLoading);
  const user = useSelector(getUser);
  const [filterOptions, setFilterOptions] = useState(initializeFilterState);
  const [searchText, setSearchText] = useState('');
  const [masterSearchQuery, setMasterSearchQuery] = useState({});
  const [studiesLoading, setStudiesLoading] = useState({ loading: false, newSearch: false });
  const [pediatricCheckbox, setPediatricCheckbox] = useState(false);
  const [remountKey, setRemountKey] = useState(0);
  const [searchOccuredFlag, setSearchOccuredFlag] = useState(false);
  // const [currentPage, setCurrentPage] = useState(1);
  const [dictionariesLoading, setDictionariesLoading] = useState(false);
  const [naturalSynonymsDictionary, setNaturalSynonymsDictionary] = useState({});
  const [conventionalSynonymsDictionary, setConventionalSynonymsDictionary] = useState({});
  const [summarizedStudies, setSummarizedStudies] = useState({ 
    results: [],
    fullCount: 0
  });
  const [supportLit, setSupportLit] = useState({ 
    results: [],
    fullCount: 0
  });

  useEffect(() => {
    setDictionariesLoading(true);
    getSynonymDictionaries()
      .then(({ naturalSynonymsDictionary, conventionalSynonymsDictionary }) => {
        naturalTherapyAgents = ['Natural Therapy Agents', 'All Natural Therapy Agents', ...Object.keys(naturalSynonymsDictionary).map(key => key.includes('"') ? key.slice(1, -1) : key)];
        conventionalTreatmentAgents = ['Conventional Treatment Agents', 'All Conventional Treatment Agents', ...Object.keys(conventionalSynonymsDictionary).map(key => key.includes('"') ? key.slice(1, -1) : key)];
        setNaturalSynonymsDictionary(naturalSynonymsDictionary);
        setConventionalSynonymsDictionary(conventionalSynonymsDictionary);
        setDictionariesLoading(false);
      })
      .catch(err => {
        console.log(err);
        setDictionariesLoading(false);
      });
  }, []);
  
  function handleSubmit({ event = null, showSupportLit = true, newSearch = false }) {
    if(event) {
      event.preventDefault();
    }
    clearStudies();
    const searchQuery = createQuery(filterOptions, searchText, pediatricCheckbox);
    if(searchQuery !== emptyQuery) {
      setStudiesLoading({  loading:true, newSearch });
      setMasterSearchQuery(searchQuery);
      executeSearch(searchQuery, setSummarizedStudies, showSupportLit ? setSupportLit : null)
        .then(() => {
          setSearchOccuredFlag(true);
          setStudiesLoading({ loading:false, newSearch });
        });
      // setCurrentPage(1);
    }
  }

  const initialRender = useRef(true);
  useEffect(() => {
    if(!initialRender.current){
      //organization._id = 3 is the GUESTS organizations
      handleSubmit({ event: null, showSupportLit: !pediatricCheckbox && (user.organizations !== 3 && user.role !== 'allied'), newSearch: true });
    } else initialRender.current = false;
  }, [filterOptions, pediatricCheckbox]);

  if(dictionariesLoading || userLoading) return <LoadingSpinner />;

  if(!user.email) return <Redirect to='/login' />;

  function clearStudies() {
    setSummarizedStudies({ 
      results: [],
      fullCount: 0
    });
    setSupportLit({ 
      results: [],
      fullCount: 0
    });
  }

  const handlePediatricChange = () => setPediatricCheckbox(!pediatricCheckbox);
  const handleSearchText = ({ target }) => setSearchText(target.value);
  const handleClear = () => {
    setRemountKey(remountKey + 1);
    setSearchOccuredFlag(false);
    clearStudies();
    setSearchText('');
    initialRender.current = true;
    // setCurrentPage(1);
    setPediatricCheckbox(false);
    setFilterOptions(initializeFilterState());
  };
  const handleFilterChange = newOptions => setFilterOptions(newOptions);

  // const handlePagination = (page, search) => {
  //   search.page = page;
  //   setCurrentPage(page);
  //   executeSearch(search, setSummarizedStudies, setSupportLit);
  // };

  const filteredKeys = Object.entries(filterOptions).filter(([, [firstFilter]]) => !firstFilter.startsWith('All ')).map(([key]) => key);

  const { searchContainer, submit, textInput, searchButtonContainer, searchButton, checkbox } = styles;
  return (
    <>
      <section className={searchContainer}>
        <form className={submit} onSubmit={e => handleSubmit({ event: e, showSupportLit: !pediatricCheckbox && (user.organizations !== 3 && user.role !== 'allied'), newSearch: true })}>
          <span>
            <SearchInfo />
            Search:
          </span>
          <input type='text' className={textInput} value={searchText} onChange={handleSearchText} />
          <div className={searchButtonContainer}>
            <button type='submit' className={searchButton} value='Search'>Search</button>
            <button type='button' className={searchButton} onClick={handleClear}>Clear</button>
          </div>
          <div>
            <label><input className={checkbox} type='checkbox' checked={pediatricCheckbox} onChange={handlePediatricChange} />Pediatric Results Only<TiFilter /></label>
          </div>
        </form>
        <ModalGroup key={remountKey} modalGroupArray={filterGroupArrays} setCallback={handleFilterChange} includeAllOption={true} styles={styles}>
          <SynonymModal key={naturalTherapyAgents[0]} optionsArray={naturalTherapyAgents} includeAllOption={true} synonymsDictionary={naturalSynonymsDictionary} setCallback={_ => _}/>
          <SynonymModal key={conventionalTreatmentAgents[0]} optionsArray={conventionalTreatmentAgents} includeAllOption={true} synonymsDictionary={conventionalSynonymsDictionary} setCallback={_ => _}/>
        </ModalGroup>
      </section>
      <ResultsSection
        summarizedStudies={summarizedStudies}
        filteredKeys={filteredKeys}
        supportLit={supportLit}
        masterSearchQuery={masterSearchQuery}
        // pageCallback={handlePagination}
        searchOccuredFlag={searchOccuredFlag}
        // currentPage={currentPage}
        // setCurrentPage={setCurrentPage}
        studiesLoading={studiesLoading} />
    </>
  );
};

export default Studies;
