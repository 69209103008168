import React from 'react';

const AllCancerTypes = () => (
  <>
    <h1>KNOW Methodology &ndash; All cancer types</h1>
    <br/>
    <h2>RESEARCH QUESTION:</h2>
    <p>What is the role of integrative therapies in the clinical management of cancer patients and survivors?</p>
    <br/>
    <h2>INCLUSION CRITERIA:</h2>
    <p>English language<br />Articles published in the years 2010 to present<br />Human experimental studies including meta-analyses, systematic reviews, clinical trials, observational data, case reports (population must include humans with cancer or survivors)<br />Population includes humans with cancer (all tumor types) and survivors of cancer (i.e., diagnosed with cancer and forward in time)<br />All outcomes including: Overall Survival (OS), Progression Free Survival (PFS), Recurrence, Quality of Life (QoL), side effect management, Patient Reported Outcomes (PROs), and Clinical Practice Guidelines (CPGs)<br />Females and males<br />All nutraceuticals (including oral supplements, botanicals, homeopathy, dietary, intravenous, injectable, topical, natural agent complexed to pharmaceutical)<br />Diet therapy (Mediterranean, Ornish, vegetarian, etc.)<br />If combined with nutraceutical in study: Mind body, exercise therapy, yoga therapy, prayer, hypnotherapy, light therapy, hydrotherapy, acupuncture and oriental medicine</p>
    <br/>
    <h2>EXCLUSION CRITERIA:</h2>
    <p>Non-English language<br />Non-human experimental (e.g., in vitro or in vivo non-human animal)<br />Studies that do not include cancer population or survivors<br />Any condition NOT resulting from cancer or treatment of cancer<br />Narrative review<br />Drug-only trials<br />Prevalence/usage of CAM<br />Conference proceedings<br />Trial postings without results; interim analyses</p>
    <p>NOTE: At this time, we are including data relevant to secondary prevention i.e., studies conducted with participants after diagnosis of cancer and survivors. We are NOT including studies on the primary prevention of cancer.</p>
    <br/>
    <h2>KNOW Search Filters</h2>
    <p>Using the dietary supplement subset available in both PubMed <a href="https://www.nlm.nih.gov/bsd/pubmed_subsets/diet_strategy.html" target="_blank" rel="noopener">https://www.nlm.nih.gov/bsd/pubmed_subsets/diet_strategy.html</a> and MEDLINE as our base, we created a line-by-line version of this filter for use in Embase. With the help of naturopathic doctors on the team, we identified additional relevant vocabulary that we incorporated into the filter. This filter was then paired with the pertinent vocabulary for &ldquo;all cancers&rdquo;.</p>
    <p>We run search updates in Ovid MEDLINE&reg; (which includes in-process and Epub ahead-of-print records) and Embase. Strategies utilize a combination of controlled vocabulary (e.g., Neoplasms, Flavonoids, Homeopathy) and keywords (e.g., cancer, glutamine, probiotic). Vocabulary and syntax are adjusted across the databases. There are no language limits but animal-only, opinion pieces, and conference proceedings are removed from the results. Duplicates are removed using bibliographic software and through screening processes. Searches are updated on a regular basis (e.g., semi-annually) and relevant citations incorporated.</p>
    <br/>
  </>
);

export default AllCancerTypes;
