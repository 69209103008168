// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, "._3S_dki7RIRoJcisyad77mw {\r\n  display: flex;\r\n    flex-direction: column;\r\n    text-align: left;\r\n    top: 50%;\r\n    left: 50%;\r\n    position: fixed;\r\n    overflow: auto;\r\n    z-index: 3;\r\n    margin: 5% auto;\r\n    padding: 1rem;\r\n    border: solid #dddddd 1px;\r\n    border-radius: 5px;\r\n    background-color: #f6f6f6;\r\n    min-width: 7em;\r\n    width: auto;\r\n    height: 60%;\r\n    transform: translate(-50%, -50%);\r\n}\r\n\r\n._30mbIoEiA7hnruSVzSLdIT {\r\n  position: absolute;\r\n  z-index: 2;\r\n  width: 100vw; \r\n  height: 100vh;\r\n  background-color: #bbbaba6c;\r\n}", "",{"version":3,"sources":["EditUser.css"],"names":[],"mappings":"AAAA;EACE,aAAa;IACX,sBAAsB;IACtB,gBAAgB;IAChB,QAAQ;IACR,SAAS;IACT,eAAe;IACf,cAAc;IACd,UAAU;IACV,eAAe;IACf,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,WAAW;IACX,WAAW;IACX,gCAAgC;AACpC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,aAAa;EACb,2BAA2B;AAC7B","file":"EditUser.css","sourcesContent":[".editModal {\r\n  display: flex;\r\n    flex-direction: column;\r\n    text-align: left;\r\n    top: 50%;\r\n    left: 50%;\r\n    position: fixed;\r\n    overflow: auto;\r\n    z-index: 3;\r\n    margin: 5% auto;\r\n    padding: 1rem;\r\n    border: solid #dddddd 1px;\r\n    border-radius: 5px;\r\n    background-color: #f6f6f6;\r\n    min-width: 7em;\r\n    width: auto;\r\n    height: 60%;\r\n    transform: translate(-50%, -50%);\r\n}\r\n\r\n.backgroundDiv {\r\n  position: absolute;\r\n  z-index: 2;\r\n  width: 100vw; \r\n  height: 100vh;\r\n  background-color: #bbbaba6c;\r\n}"]}]);
// Exports
exports.locals = {
	"editModal": "_3S_dki7RIRoJcisyad77mw",
	"backgroundDiv": "_30mbIoEiA7hnruSVzSLdIT"
};
module.exports = exports;
