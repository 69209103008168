import { TOGGLE_CHECK, RESET, SET_CHECKBOX_STATE, REINITIALIZE_STATE } from '../actions/checkboxActions';
import { allFilters } from '../../utils/dropdownSeedData';

export const initializeState = () => {
  const initialState = {};
  allFilters.forEach(filter => {
    initialState[filter[0]] = [];
    filter.slice(1).forEach((filterOption, i) => {
      initialState[filter[0]].push({});
      initialState[filter[0]][i].field = filterOption;
      initialState[filter[0]][i].isChecked = false;
    });
    initialState[filter[0]][0].isChecked = true;
  });
  return initialState;
};

const checkForAllFalse = filterType => {
  const trueFilters = filterType.filter(filterOption => filterOption.isChecked);
  if(!trueFilters.length) filterType[0].isChecked = true;
};

export function reducer(state = initializeState(), { type, payload }){
  const newState = { ...state };

  switch(type){
    case TOGGLE_CHECK:
      newState[payload.title][payload.index].isChecked = !newState[payload.title][payload.index].isChecked;
      newState[payload.title][0].isChecked = false;
      checkForAllFalse(newState[payload.title]);
      return newState;
    case RESET:
      newState[payload.title].map(option => option.isChecked = false);
      newState[payload.title][0].isChecked = true;
      return newState;
    case SET_CHECKBOX_STATE:
      return Object.assign(newState, payload);
    case REINITIALIZE_STATE:
      return initializeState();
    default:
      return state;
  }
}
