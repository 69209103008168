import React from 'react';
import PropTypes from 'prop-types';
import styles from './results.css';

const propTypes = {
  studyCounts: PropTypes.shape({
    fullCount: PropTypes.number,
    systematicCount: PropTypes.number,
    clinicalCount: PropTypes.number,
    observationalCount: PropTypes.number,
    caseCount: PropTypes.number,
    unknownCount: PropTypes.number
  }),
  supportLitCount: PropTypes.number,
  searchFlag: PropTypes.bool
};

export default function ResultSectionHeaderMkII({ studyCounts, supportLitCount, searchFlag }) {

  return (
    <h2>
      <strong style={{ marginRight: '4px' }}>Results{searchFlag && ` (${studyCounts.fullCount + supportLitCount})`}</strong>
      {!!studyCounts.systematicCount && <span title="Systematic Reviews/Meta Analyses" className={`${styles.resultCount} ${styles.sysBadge}`}>
        {studyCounts.systematicCount}
      </span>}
        &nbsp;
      {!!studyCounts.clinicalCount && <span title="Clinical Trials" className={`${styles.resultCount} ${styles.clinBadge}`}>
        {studyCounts.clinicalCount}
      </span>}
        &nbsp;
      {!!studyCounts.observationalCount && <span title="Observational" className={`${styles.resultCount} ${styles.obsBadge}`}>
        {studyCounts.observationalCount}
      </span>}
        &nbsp;
      {!!studyCounts.caseCount && <span title="Case studyCounts" className={`${styles.resultCount} ${styles.caseBadge}`}>
        {studyCounts.caseCount}
      </span>}
        &nbsp;
      {!!studyCounts.unknownCount && <span title="Pending Summary" className={`${styles.resultCount} ${styles.pendBadge}`}>
        {studyCounts.unknownCount}
      </span>}
        &nbsp;
      {!!supportLitCount && <span title="Support Literature" className={`${styles.resultCount} ${styles.supBadge}`}>
        {supportLitCount}
      </span>}
    </h2>
  );
}

ResultSectionHeaderMkII.propTypes = propTypes;
